export default {
  loading: 'Loading...',
  'copy-success': 'Copy Successfully',
  label: {
    allUpp: 'ALL',
    bonus: 'Bonus',
    casino: 'Casino',
    balance: 'Balance',
    topUpp: 'TOP',
    allGames: 'All Games',
  },
  category: {
    label: {
      odds: 'Odds of',
      superJackpot: 'Super Jackpot',
      categories: 'Categories',
      provider: 'Providers',
      default: 'Default',
      popular: 'Popular',
    },
  },
  gameModal: {
    tip: {
      choose: 'Choose Your Game Mode',
      desc1: "Choose 'Free Play' to explore the game",
      desc2: "without real money, or select 'Start Game'",
      desc3: 'for the complete experience.',
    },
    label: {
      start: 'Start Game',
      free: 'Free Play',
    },
  },
  footer: {
    tip: {
      download: 'Download the app for the best Indibet experience.',
      intro1: 'durjagame Mainly Features Online Video Games, Slots and Casino.',
      intro2:
        'We work with more than 10,000 video games, slot and casino providers, all of whom are certified as fair gambling, support fast deposits and withdrawals, and friendly service. Gambling Is Addictive, Please Play It Wisely. POP888 Only Accepts Customers over the Age of 18.',
    },
  },
  home: {
    label: {
      login: 'LOG IN',
      register: 'REGISTER',
      'lucky-spin': 'Lucky Spin',
      winningAmount: 'Winning Amount',
      imgFail: 'Image failed to load',
    },
    tab: {
      sports: 'Sports',
    },
    dice: {
      title: 'Dice Game',
    },
    color: {
      title: 'Color Prediction',
    },
    'live-casino': {
      title: 'Live Casino',
    },
    title: {
      scratch: 'Scratch',
      winningInformation: 'Winning Information',
      todayEarningsChart: "Today's earnings chart",
    },
  },
  me: {
    label: {
      luckyspin: 'Luckyspan',
    },
    new: 'NEW',
    user: {
      login: 'Log in',
      uid: 'UID:',
      lastLogin: 'Last login:',
    },
    money: {
      totalBalance: 'Total balance',
      recharge: 'Recharge',
      withdraw: 'Withdraw',
    },
    vip: {
      recharge: 'Recharge',
      move: 'more to reach level',
    },
    bottom: {
      recharge: 'Recharge',
      withdraw: 'Withdraw',
      transfer: 'Transfer',
      proxy: 'Agency Center',
      logout: 'LOG OUT',
      resultHistory: 'Result history',
      myBets: 'My Bets',
      myTransactions: 'My Transactions',
      commission: 'My Commission',
      notify: 'Notifications',
      lang: 'Languages',
      customer: 'Customer service',
      update: 'Update',
      password: 'Change Password',
    },
    language: {
      switch: 'Switch Language',
    },
    tip: {
      recharge: 'Add funds to an account',
      withdraw: 'Withdraw to a bank card',
      transfer: 'Convert to Cash Balance',
      luckyspin: "Don't miss out on the cash lottery",
      vip: 'Membership\nBenefits',
    },
  },
};
