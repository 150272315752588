import React from 'react';
import {useTranslation} from 'react-i18next';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/virtual';
import {toPriceStr, useScreenSize} from '@/utils';
import {Image} from 'antd-mobile';
import {Autoplay, Virtual} from 'swiper/modules';
import {WinnerItem} from '../home.type';
import {useMemo} from 'react';

interface RecentBigWinsProps {
  list?: (WinnerItem & {id: string})[];
}

const RecentBigWins = ({list = []}: RecentBigWinsProps) => {
  const {t} = useTranslation();
  const {calcActualSize} = useScreenSize();

  const showList = useMemo(() => {
    if (list.length > 20) {
      return list.slice(list.length - 20);
    }
    return list;
  }, [list]);

  return (
    <div className="flex flex-col gap-2 mt-2">
      <div className="flex flex-row px-3 gap-1 items-center">
        <div className="size-2 rounded-full bg-c1" />
        <span className="text-t1 text-xs font-bold">
          {t('home.label.recentBigWins')}
        </span>
      </div>
      <Swiper
        style={{width: '100%'}}
        modules={[Autoplay, Virtual]}
        className="px-3"
        slidesPerView={'auto'}
        loop={true}
        spaceBetween={calcActualSize(8)}
        autoplay={{delay: 3000, waitForTransition: false}}>
        {showList.map((item, index) => (
          <SwiperSlide
            key={item.id}
            virtualIndex={index}
            className="w-[3.5rem]">
            <div className="flex flex-col gap-1">
              <Image
                src={item.gamePic}
                width={'3.5rem'}
                height={'4.625rem'}
                className="rounded-lg"
              />
              <div className="flex flex-col gap-0.5">
                <div className="flex flex-row gap-1 items-center justify-center">
                  <Image
                    width={'.75rem'}
                    height={'.75rem'}
                    src={item.userAvatar}
                    className="border border-[#12A218] rounded-full"
                  />
                  <span className="text-t1 text-[.5rem]">{item.userName}</span>
                </div>
                <span className="text-center font-bold text-[.5rem] text-c1">
                  {toPriceStr(item.bonus, {suffixUnit: 'k'})}
                </span>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default RecentBigWins;
