import NavTitle from '@/components/v2/basic/nav-title';
import Balance from '@/components/v2/business/balance/balance';
import React, {useState} from 'react';
import HomeSide, {useTop} from '@/components/v2/business/homeside';

import {useTranslation} from 'react-i18next';
import {useRoute} from '@react-navigation/native';
import {SafeAny} from '@/types';
import CategoryContent from './content';
import Spin from '@/components/v2/basic/spin';

const CasinoDetail = () => {
  const {params} = useRoute<SafeAny>();
  const [category, setCategory] = useState(params.categoryName || '');
  const {t} = useTranslation();
  const {parentRef, hasTop, onTop} = useTop<HTMLDivElement>();
  const [loading, setLoading] = useState(false);
  return (
    <div className="flex flex-col overflow-hidden bg-bg w-full h-screen">
      <NavTitle
        title={category || t('category.label.categories')}
        rightNode={<Balance />}
      />
      <Spin loading={loading}>
        <div className="flex flex-col flex-1 overflow-auto" ref={parentRef}>
          <CategoryContent
            category={category}
            onCategoryChange={setCategory}
            onLoading={setLoading}
            onReloadList={onTop}
          />
        </div>
      </Spin>

      <HomeSide hasTop={hasTop} onTop={onTop} />
    </div>
  );
};

export default CasinoDetail;
