import React, {useMemo} from 'react';
import {FC} from 'react';
import DetailNavTitle from './components/business/detail-nav-title';
import Spin from './components/basic/spin';
import theme from './style';
import globalStore from './services/global.state';
import {TabBarList} from './main-navigation';
import {mainPageList} from './main-nav.config';

interface MainBetByProps {
  inTab?: boolean;
}
const MainBetBy: FC<MainBetByProps> = ({inTab = false}) => {
  const betbyRender = useMemo(() => {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'none',
          flexDirection: 'column',
          overflow: 'hidden',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        id="betby-container">
        {!inTab && (
          <DetailNavTitle
            onBack={
              inTab
                ? undefined
                : () => globalStore.betbyBackSubject.next('back')
            }
            hideServer
            title="Sports"
          />
        )}
        <div id="betby" style={{flex: 1, overflow: 'auto'}}>
          <Spin loading style={theme.fill.fill} />
        </div>
        {inTab && (
          <TabBarList
            list={mainPageList.map(item => ({name: item.name}))}
            activeIndex={mainPageList.findIndex(item => item.name === 'BetBy')}
          />
        )}
      </div>
    );
  }, [inTab]);
  return betbyRender;
};

export default MainBetBy;
