import theme from '@style';
import {MeListItem} from '@businessComponents/list-item';
import React from 'react';
import {Platform, StyleSheet, View} from 'react-native';
import Text from '@basicComponents/text';
import globalStore from '@/services/global.state';
import {goCS, goTo, toAgentApply} from '@/utils';
import {useConfirm} from '@basicComponents/modal';
import {useLanguageModal} from '@businessComponents/language';
import {useVersionModal} from '@/common-pages/hooks/versionmodal.hooks';
import {useInnerStyle} from './me.hooks';
import {IUserInfo, MessageCountInfo} from '@/services/global.service';
import {getVersion} from 'react-native-device-info';
import Tag from '@basicComponents/tag';
import {useTranslation} from 'react-i18next';
import {Button} from 'antd-mobile';
const moneyIcon = require('@assets/icons/me/bets.svg').default;
const centerIcon = require('@assets/icons/me/center.svg').default;
const transactionsIcon = require('@assets/icons/me/transactions.svg').default;
const commissionIcon = require('@assets/icons/me/commission.svg').default;

const notificationsIcon = require('@assets/icons/me/notifications.svg').default;
const passwordIcon = require('@assets/icons/me/password.svg').default;
const languagesIcon = require('@assets/icons/me/languages.svg').default;
const customerServiceIcon = require('@assets/icons/me/service.svg').default;
const updateIcon = require('@assets/icons/me/update.svg').default;
const resultHistoryIcon =
  require('@assets/icons/me/result-history.svg').default;
export interface MeListsProps {
  messageCountInfo: MessageCountInfo;
  showNoMenu: boolean;
  login: boolean;
  user?: IUserInfo;
}

const MeLists: React.FC<MeListsProps> = ({login, messageCountInfo, user}) => {
  const {i18n} = useTranslation();
  const {renderModal: renderConfirmModal, show: confirmShow} = useConfirm();
  const {renderModal: renderLanguageModal, show: languageShow} =
    useLanguageModal();
  const {versionModal, handleUpdate} = useVersionModal(false);
  const {listStyle} = useInnerStyle();
  const toTransactions = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    goTo('Transactions');
  };

  const toMyBets = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    goTo('Bets');
  };

  const toNotify = () => {
    // 跳转notify
    // globalStore.globalTotal.next(notYetWarning);
    if (!login) {
      goTo('Login');
    } else {
      goTo('Notification');
    }
  };

  const toLanguage = () => {
    languageShow();
  };

  const toUpdate = () => {
    // 更新
    handleUpdate();
  };

  const doLogout = () => {
    // logout
    confirmShow(i18n.t('alert.logout'), i18n.t('alert.sureLogout'), () => {
      globalStore.token = null;
      goTo('Login');
    });
  };

  const toSetPassword = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    goTo('SetPassword');
  };

  const toAgency = () => {
    if (user?.isAgent === 1) {
      goTo('ProxyHome');
    } else {
      toAgentApply();
    }
  };
  const toResults = () => {
    goTo('Result');
    // postMessage('tabbar:/pages/results/results');
  };
  return (
    <div className="flex flex-col gap-2 mt-2">
      {/* 列表区域 */}
      <View style={styles.content}>
        <MeListItem
          icon={resultHistoryIcon}
          title={i18n.t('me.bottom.resultHistory')}
          onPress={toResults}
        />
        <MeListItem
          icon={moneyIcon}
          title={i18n.t('me.bottom.myBets')}
          onPress={toMyBets}
        />

        <MeListItem
          icon={centerIcon}
          title={i18n.t('me.bottom.proxy')}
          rightContent={
            <View
              style={[theme.flex.center, theme.padding.lrxs, listStyle.new]}>
              <Text fontSize={8} style={{color: '#fff'}}>
                {i18n.t('me.new')}
              </Text>
            </View>
          }
          onPress={toAgency}
        />

        <MeListItem
          icon={transactionsIcon}
          title={i18n.t('me.bottom.myTransactions')}
          onPress={toTransactions}
        />
        <MeListItem
          icon={commissionIcon}
          onPress={() => goTo('Invite')}
          title={i18n.t('me.bottom.commission')}
        />
      </View>
      <View style={styles.content}>
        <MeListItem
          icon={notificationsIcon}
          title={i18n.t('me.bottom.notify')}
          rightContent={
            messageCountInfo && messageCountInfo.messageTotalCount ? (
              <Tag content={messageCountInfo.messageTotalCount} />
            ) : null
          }
          onPress={toNotify}
        />
        <MeListItem
          icon={passwordIcon}
          title={i18n.t('me.bottom.password')}
          onPress={toSetPassword}
        />
        <MeListItem
          icon={languagesIcon}
          title={i18n.t('me.bottom.lang')}
          onPress={toLanguage}
        />
        <MeListItem
          icon={customerServiceIcon}
          title={i18n.t('me.bottom.customer')}
          onPress={goCS}
        />
        {Platform.OS === 'android' && (
          <MeListItem
            icon={updateIcon}
            title={i18n.t('me.bottom.update')}
            rightContent={
              <Text style={[theme.font.secAccent, theme.font.fs]}>
                {getVersion()}
              </Text>
            }
            onPress={toUpdate}
          />
        )}
      </View>
      {login && (
        <Button
          onClick={doLogout}
          className="h-12 flex items-center justify-center p-0 !text-t2 text-sm bg-button-s rounded-lg">
          {i18n.t('me.bottom.logout')}
        </Button>
      )}
      {renderConfirmModal}
      {renderLanguageModal}
      {versionModal.renderModal}
    </div>
  );
};

const styles = StyleSheet.create({
  content: {
    backgroundColor: 'var(--card)',
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderRadius: 8,
  },
});
export default MeLists;
