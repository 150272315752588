import React from 'react';
import {BannerListItem} from '../home.type';
import {Swiper, Image} from 'antd-mobile';

export interface BannerProp {
  list?: BannerListItem[];
}
export default function Banner({list}: BannerProp) {
  return !!list && !!list.length ? (
    <Swiper loop autoplay className="flex-none">
      {list.map((item, index) => (
        <Swiper.Item key={index} className="rounded-xl overflow-hidden">
          <div className="w-[21.9375rem] h-[12.5rem]">
            <Image src={item.bannerImg} width="21.9375rem" height="12.5rem" />
          </div>
        </Swiper.Item>
      ))}
    </Swiper>
  ) : null;
}
