import {packageId} from '@/config';
import * as baseVariable from '@components/style/base.variable';

// // 为了方便后期更换方案时整理,这个文件暂时统一在master维护,做条件判断

switch (packageId) {
  case 2:
    // bhau
    baseVariable.fontColor.main = '#121410';
    baseVariable.fontColor.second = '#48505D';
    baseVariable.fontColor.accent = '#8D97AB';
    baseVariable.basicColor.primary = '#00B512';
    baseVariable.basicColor.red = '#D64D00';
    baseVariable.backgroundColor.main = '#00B512';
    baseVariable.basicColor.primaryLinearGradient = ['#00B512', '#00910E'];
    baseVariable.basicColor.proxyLinearGradient = [
      'rgba(0, 134, 70, 0.00)',
      '#00562D',
    ];
    baseVariable.basicColor.proxybgColor = '#008646';
    baseVariable.basicColor.proxyResetLink = '#DDFFE1';
    baseVariable.basicColor.proxylightLinear = ['#00C07B', '#00DA30'];
    baseVariable.basicColor.loginLinearGradient = ['#E8FFEB', '#FFF'];
    baseVariable.basicColor.rechargeButtonLinearGradient = [
      '#00B512',
      '#00B512',
    ];
    baseVariable.basicColor.invitationRecordLinearGradient = [
      '#CBFBD8',
      'rgba(255, 255, 255, 0.00)',
    ];
    baseVariable.basicColor.invitationRuleColors = {
      tableHeader: '#0C961A',
      tableEven: '#D4F8D6',
      tableOdd: '#E6FDE7',
    };
    baseVariable.backgroundColor.lightGrey = '#F4F5F8';
    baseVariable.backgroundColor.sattaDigitShadow = '#00F51880';
    break;
  case 3:
    // singam
    baseVariable.backgroundColor.timeLinearGradient = ['#DCD1FC', '#E6D7FF'];
    baseVariable.backgroundColor.remainingLinearGradient = [
      '#D6CAFA',
      '#E6D6FF',
    ];
    baseVariable.backgroundColor.ruleBtn = '#EBE2FF';
    baseVariable.backgroundColor.diceResultBg = '#B098D8';
    baseVariable.backgroundColor.diceResultBgLight = '#D6C6F1';
    baseVariable.backgroundColor.rateItem = '#F6E9FF';
    baseVariable.backgroundColor.colorQuickLinearGradient = [
      '#DACEED',
      '#D4C8E9',
    ];
    baseVariable.backgroundColor.colorBall = '#C2B6D5';
    baseVariable.backgroundColor.colorBets = '#ADA4BC';
    baseVariable.backgroundColor.colorBetsListBg = '#746D80';
    baseVariable.backgroundColor.colorBetsListBgLight = '#958BA6';
    baseVariable.backgroundColor.colorBetsDotBg = '#6B6477';
    baseVariable.fontColor.colorMyBets = '#374046';
    baseVariable.basicColor.darkPrimary = '#6F00B4';
    baseVariable.backgroundColor.colorDashed = '#897AA1';
    break;
  case 5:
    // 99
    baseVariable.basicColor.primary = '#D90000';
    baseVariable.basicColor.red = '#FF382C';
    baseVariable.basicColor.primaryLinearGradient = ['#DB0000', '#AB0000'];
    baseVariable.fontColor.main = '#252529';
    baseVariable.fontColor.second = '#45454d';
    baseVariable.fontColor.accent = '#848490';
    baseVariable.fontColor.secAccent = '#bbbbc5';
    baseVariable.backgroundColor.main = baseVariable.basicColor.primary;
    baseVariable.backgroundColor.second = '#3988FD';
    baseVariable.backgroundColor.accent = '#FF7E2B';
    baseVariable.backgroundColor.secAccent = '#FFB21E';
    baseVariable.backgroundColor.grey = '#dcdce5';
    baseVariable.backgroundColor.palegrey = '#DEE2E8';
    baseVariable.backgroundColor.lightGrey = '#F6F7FA';
    baseVariable.invitationBaseColors.signGiftBgColor = '#F16626';
    baseVariable.invitationBaseColors.registSuccess = '#009B22';
    baseVariable.backgroundColor.timeLinearGradient = ['#FCD1D1', '#FFFFFF'];
    baseVariable.backgroundColor.remainingLinearGradient = [
      '#FFD9D9',
      '#FFF0F0',
    ];
    baseVariable.backgroundColor.ruleBtn = '#FFEBEB';
    baseVariable.backgroundColor.diceResultBg = '#D89898';
    baseVariable.backgroundColor.diceResultBgLight = '#FFC5C5';
    baseVariable.backgroundColor.rateItem = '#F6E9FF';
    baseVariable.backgroundColor.colorQuickLinearGradient = [
      '#FFDBDB',
      '#FFEAEA',
    ];
    baseVariable.backgroundColor.colorBall = '#D5B6B6';
    baseVariable.backgroundColor.colorBets = '#BCA4A4';
    baseVariable.backgroundColor.colorBetsListBg = '#806D6D';
    baseVariable.backgroundColor.colorBetsListBgLight = '#A68B8B';
    baseVariable.backgroundColor.colorBetsDotBg = '#776464';
    baseVariable.fontColor.colorMyBets = '#463737';
    baseVariable.basicColor.darkPrimary = '#B40000';
    baseVariable.backgroundColor.colorDashed = '#897AA1';
    baseVariable.backgroundColor.betsBgDark = '#CD0000';
    baseVariable.backgroundColor.betsBgLight = '#FFF0F0';
    break;

  case 7:
    // turkey
    baseVariable.basicColor.primary = '#FF6900';
    baseVariable.basicColor.red = '#FF382C';
    baseVariable.basicColor.primaryLinearGradient = ['#FF6900', '#CC5400'];
    baseVariable.fontColor.main = '#FFF';
    baseVariable.fontColor.second = '#C3C9D2';
    baseVariable.fontColor.accent = '#98A3B7';
    baseVariable.fontColor.secAccent = '#5B5F67';
    baseVariable.backgroundColor.main = baseVariable.basicColor.primary;
    baseVariable.backgroundColor.second = '#3988FD';
    baseVariable.backgroundColor.accent = '#FF7E2B';
    baseVariable.backgroundColor.secAccent = '#FFB21E';
    baseVariable.backgroundColor.grey = '#dcdce5';
    baseVariable.backgroundColor.palegrey = '#DEE2E8';
    baseVariable.backgroundColor.lightGrey = '#0B0C0E';
    baseVariable.invitationBaseColors.signGiftBgColor = '#F16626';
    baseVariable.invitationBaseColors.registSuccess = '#009B22';
    baseVariable.backgroundColor.timeLinearGradient = ['#FCD1D1', '#FFFFFF'];
    baseVariable.backgroundColor.remainingLinearGradient = [
      '#FFD9D9',
      '#FFF0F0',
    ];
    baseVariable.basicColor.white = 'var(--BG)';
    baseVariable.backgroundColor.diceResultBg = '#D89898';
    baseVariable.backgroundColor.diceResultBgLight = '#FFC5C5';
    baseVariable.backgroundColor.rateItem = '#F6E9FF';
    baseVariable.backgroundColor.colorQuickLinearGradient = [
      '#4B5161',
      '#4F5564',
    ];
    baseVariable.backgroundColor.timeLinearGradient = ['#874B13', '#874B13'];
    baseVariable.backgroundColor.remainingLinearGradient = [
      '#874B13',
      '#703803',
    ];
    baseVariable.backgroundColor.diceResultBg = 'rgba(0, 0, 0, 0.50)';
    baseVariable.backgroundColor.diceResultBgLight = '#715858';
    baseVariable.backgroundColor.ruleBtn = '#D07000';
    baseVariable.backgroundColor.colorDashed = '#897AA1';
    baseVariable.backgroundColor.colorBall = '#393E4A';
    baseVariable.backgroundColor.colorBets = '#636979';
    baseVariable.backgroundColor.colorBetsListBg = '#393E4A';
    baseVariable.backgroundColor.colorBetsListBgLight = '#4F5564';
    baseVariable.backgroundColor.colorBetsDotBg = '#393E4A';
    baseVariable.fontColor.colorMyBets = '#393E4A';
    baseVariable.basicColor.darkPrimary = '#B40000';
    baseVariable.backgroundColor.colorDashed = '#897AA1';
    baseVariable.backgroundColor.betsBgDark = '#CD0000';
    baseVariable.backgroundColor.betsBgLight = '#FFF0F0';
    baseVariable.basicColor.invitationRecordLinearGradient = [
      'var(--BG)',
      'var(--BG)',
    ];
    baseVariable.basicColor.proxyResetLink = 'var(--button-small)';
    baseVariable.backgroundColor.betsBgLight = 'var(--image)';
    baseVariable.basicColor.proxyLinearGradient = [
      'rgba(0, 0, 0, 0)',
      'rgba(0, 0, 0, 0.5)',
    ];
    baseVariable.basicColor.proxybgColor = '#E75300';
    break;
}

export default baseVariable;
