import {Header} from '@/components/v2/business/category/category';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as WinInfoSvg} from '../svgs/wininfo-header-wrap.svg';
import {Image} from 'antd-mobile';
import styles from './winning-information.module.scss';
import {toPriceStr} from '@/utils';
import {WinnerItem} from '../home.type';

interface WinningInformationItemProps {
  avatar?: string;
  rank?: number;
  userName: string;
  gamePic?: string;
  amount: number;
}

const WinningInformationItem: FC<WinningInformationItemProps> = ({
  avatar,
  rank,
  userName,
  gamePic,
  amount,
}) => {
  const {t} = useTranslation();
  return (
    <div className={`${styles['win-item']} relative`}>
      <div
        className={
          'absolute top-1 left-1 flex items-center justify-center w-11 h-11'
        }>
        <WinInfoSvg className="absolute top-0 left-0 w-11 h-11 text-c1" />
        <Image
          src={avatar}
          width="2.375rem"
          height="2.375rem"
          className="rounded-full"
          lazy
        />
        {rank != null && (
          <div className="absolute bottom-0 right-0 rounded-full size-[1.125rem] bg-c1 text-t1 text-[.625rem] flex items-center justify-center">
            V{rank}
          </div>
        )}
      </div>
      <span className="absolute top-[1.15625rem] left-[4.266875rem] max-w-[5rem] text-t1 text-xs break-all">
        {userName}
      </span>
      <div className="absolute top-0 right-[.604375rem] h-full flex flex-row items-center gap-6">
        <Image
          src={gamePic}
          width="3.25rem"
          height="3.25rem"
          className="rounded-lg"
          lazy
        />
        <div className="flex flex-col items-end">
          <span className="text-t1 text-base font-bold">
            {toPriceStr(amount)}
          </span>
          <span className="text-t2 text-xs">
            {t('home.label.winningAmount')}
          </span>
        </div>
      </div>
    </div>
  );
};

interface WinningInformationProps {
  list: (WinnerItem & {id: string})[];
}

const WINNING_INFORMATION_MAX_LENGTH = 5;

const WinningInformation: FC<WinningInformationProps> = ({list}) => {
  const {t} = useTranslation();
  const [showLastIndex, setShowLastIndex] = useState(0);
  const nextTimerRef = useRef<NodeJS.Timeout>();
  const firstLoop = useRef(true);

  const showList = useMemo(() => {
    if (list.length <= WINNING_INFORMATION_MAX_LENGTH) {
      return list;
    }
    const _showIndex = list.length - showLastIndex;
    if (firstLoop.current) {
      return list.slice(
        _showIndex,
        _showIndex + WINNING_INFORMATION_MAX_LENGTH,
      );
    }
    if (_showIndex + WINNING_INFORMATION_MAX_LENGTH > list.length) {
      return [
        ...list.slice(_showIndex),
        ...list.slice(
          0,
          WINNING_INFORMATION_MAX_LENGTH - list.length + _showIndex,
        ),
      ];
    }
    return list.slice(_showIndex, _showIndex + WINNING_INFORMATION_MAX_LENGTH);
  }, [list, showLastIndex]);

  const doNextTimer = useCallback(
    (delay: number = 1000, randomDelay: number = 0) => {
      nextTimerRef.current = setTimeout(() => {
        setShowLastIndex(index => {
          const nextIndex = index + 1;
          doNextTimer(delay, Math.random() * 1000);
          if (nextIndex >= list.length) {
            firstLoop.current = false;
          }
          return nextIndex % list.length;
        });
      }, delay + randomDelay);
    },
    [list.length],
  );

  useEffect(() => {
    if (nextTimerRef.current != null) {
      clearTimeout(nextTimerRef.current);
      nextTimerRef.current = undefined;
    }
    doNextTimer(500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  return (
    <>
      {!!list && !!list.length && (
        <div className={'flex flex-col gap-3'}>
          <Header
            icon={require('@assets/imgs/home.07/winning-information.webp')}
            title={t('home.title.winningInformation')}
          />
          <div className={'flex flex-col px-3 overflow-hidden'}>
            <div className="flex flex-col gap-2">
              {showList?.map(item => (
                <WinningInformationItem
                  key={item.id}
                  avatar={item.userAvatar}
                  rank={item.userLevel != null ? item.userLevel : undefined}
                  userName={item.userName}
                  gamePic={item.gamePic}
                  amount={item.bonus}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WinningInformation;
