import LazyImage from '@basicComponents/image';
import React from 'react';
import {Image, StyleSheet, View} from 'react-native';
import {defaultHeaderImg, emptyHeaderImg, whiteRightIcon} from './me.variable';
import theme from '@/style';
import Text from '@basicComponents/text';
import {IUserInfo} from '@/services/global.service';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {goTo} from '@/utils';
import {useTranslation} from 'react-i18next';
import {SafeAny} from '@/types';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
const edit = require('@components/assets/pofile/edit.07.svg').default;
export interface MeUserProps {
  user?: IUserInfo;
  login: boolean;
}
const baseSize = 52;
const MeUser: React.FC<MeUserProps> = ({user, login}) => {
  const {i18n} = useTranslation();
  const {calcActualSize} = useScreenSize();
  const headerSize = calcActualSize(baseSize);
  return (
    <NativeTouchableOpacity
      activeOpacity={login ? 1 : 0.8}
      style={style.container}
      onPress={(e: SafeAny) => {
        if (!login) {
          goTo('Login');
        } else {
          e.preventDefault();
          goTo('Profile');
        }
      }}>
      <View style={style.user}>
        <View
          style={[
            theme.flex.center,
            // userStyle.headerWrap,
            // theme.background.white,
          ]}>
          <LazyImage
            width={headerSize}
            height={headerSize}
            imageUrl={
              login
                ? user?.userAvatar
                  ? user.userAvatar
                  : defaultHeaderImg
                : emptyHeaderImg
            }
            occupancy="#0000"
            radius={headerSize / 2}
          />
        </View>
        <View style={style.centerText}>
          <Text fontSize={14} style={{color: '#fff', marginBottom: 8.5}} blod>
            {(user?.userName ? user?.userName : user?.userPhone) ||
              i18n.t('me.user.login')}
          </Text>
          <View style={style.phone}>
            <Image
              style={theme.icon.s}
              source={require('@components/assets/icons/phone.webp')}
            />
            <Text fontSize={12} style={{color: '#93979F'}}>
              {user ? user.userPhone : '******'}
            </Text>
            <Image
              style={{width: 16, height: 16, marginLeft: 12}}
              source={edit}></Image>
          </View>
        </View>
      </View>
      <LazyImage
        occupancy={'transparent'}
        imageUrl={whiteRightIcon}
        width={14}
        height={14}
      />
    </NativeTouchableOpacity>
  );
};
const style = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  user: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  phone: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  centerText: {
    justifyContent: 'space-around',
  },
});

export default MeUser;
