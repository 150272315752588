import LuckySpin from './lucky-spin';

import {ReactComponent as Note} from '@assets/svgs/menu/notifications.svg';
import {ReactComponent as Casino} from '@assets/svgs/menu/casino.svg';
import {ReactComponent as Slots} from '@assets/svgs/menu/slots.svg';
import {ReactComponent as LiveCasion} from '@assets/svgs/menu/live.svg';
import {ReactComponent as Lottery} from '@assets/svgs/menu/lottery.svg';
import {ReactComponent as Sports} from '@assets/svgs/menu/sports.svg';
import {ReactComponent as Promotions} from '@assets/svgs/menu/promotions.svg';
import {ReactComponent as Lang} from '@assets/svgs/menu/language.svg';
import React from 'react';

const Close = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none">
    <path
      d="M28.8537 9.28934C30.5285 12.4973 30.9432 16.2157 30.0163 19.7138C29.0894 23.212 26.8879 26.2371 23.8444 28.1949C20.8008 30.1527 17.1351 30.9016 13.5675 30.2945C9.9999 29.6874 6.78826 27.7682 4.56355 24.9139C2.33883 22.0596 1.26186 18.4766 1.5442 14.8687C1.82654 11.2609 3.44779 7.88899 6.08944 5.41554C8.7311 2.94208 12.2022 1.54583 15.8208 1.50111C19.4394 1.45638 22.944 2.76641 25.6459 5.17383"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <rect
      x="12.292"
      y="21.8926"
      width="3"
      height="13.3333"
      rx="1.5"
      transform="rotate(-135 12.292 21.8926)"
      fill="white"
    />
    <rect
      x="21.4844"
      y="19.7712"
      width="3"
      height="13.3333"
      rx="1.5"
      transform="rotate(135 21.4844 19.7712)"
      fill="white"
    />
  </svg>
);

export default {
  LuckySpin,
  Casino,
  Slots,
  'Live casino': LiveCasion,
  Lottery,
  Sports,
  Promotions,
  Lang,
  Note,
  Close,
};
