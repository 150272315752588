import NavTitle from '@/components/v2/basic/nav-title';
import Balance from '@/components/v2/business/balance/balance';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useRequest} from 'ahooks';
import CategoryTab from './component/categoryTab';
import styles from './casino.module.scss';
import {getCasinoTabs} from './casino.service';
import HomeSide, {useTop} from '@/components/v2/business/homeside';
import Spin from '@/components/v2/basic/spin';
import CategoryContent from './category/content';
import CasinoContent from './content';
const Casino = () => {
  const {t} = useTranslation();
  const {data: categoryTabs, loading: categoryTabsLoading} =
    useRequest(getCasinoTabs);
  const [tabKey, setTabKey] = useState('');
  const [listLoading, setListLoading] = useState(false);
  const {parentRef, hasTop, onTop} = useTop<HTMLDivElement>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    onTop(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabKey]);
  useEffect(() => {
    if (categoryTabs?.length) {
      setTabKey(categoryTabs[0].moduleType);
    }
  }, [categoryTabs]);
  return (
    <div className="flex flex-col overflow-hidden bg-bg w-full h-full">
      <NavTitle title={t('label.casino')} hideBack rightNode={<Balance />} />
      <Spin loading={categoryTabsLoading || listLoading || loading}>
        <div className="flex flex-col flex-1 overflow-auto" ref={parentRef}>
          {!categoryTabsLoading ? (
            <>
              <div
                className={`p-3 sticky top-0 z-20 ${styles['category-tab']}`}>
                <CategoryTab
                  activeKey={tabKey}
                  onChange={setTabKey}
                  tabs={categoryTabs || []}
                />
              </div>
              {tabKey === 'ALL' ? (
                <CasinoContent onLoading={setListLoading} />
              ) : (
                <CategoryContent
                  hideCategories
                  category={tabKey}
                  filterClass="top-14"
                  onLoading={setLoading}
                  onReloadList={onTop}
                />
              )}
            </>
          ) : null}
        </div>
      </Spin>

      <HomeSide hasTop={hasTop} onTop={onTop} />
    </div>
  );
};

export default Casino;
