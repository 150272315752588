import Category from '@/components/v2/business/category/category';
import React, {FC, useEffect} from 'react';
import {useRequest} from 'ahooks';
import {getCasinoGames} from './casino.service';
import {goTo} from '@/utils';
import CasinoGamesCard from '@/components/v2/business/category/casino-games-card';
import {toAllCasinoGame} from '@/common-pages/game-navigate';
import NoData from '@/components/basic/error-pages/no-data';
import Footer from '@/components/v2/business/footer/footer';

interface CasinoContentProps {
  onLoading?: (loading: boolean) => void;
}

const CasinoContent: FC<CasinoContentProps> = ({onLoading}) => {
  const {data: casinoGames, loading: listLoading} = useRequest(getCasinoGames);
  useEffect(() => {
    onLoading?.(listLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listLoading]);
  return (
    <div className="flex flex-col gap-5">
      {casinoGames &&
      casinoGames.filter(g => g.gameVoList.length > 0).length ? (
        casinoGames?.map(game =>
          game?.gameVoList?.length ? (
            <Category
              col={1}
              row={1}
              list={game.gameVoList}
              key={game.name}
              total={game.all || game.gameVoList.length}
              title={game.name}
              icon={game.icon}
              onAll={() => goTo('CasinoDetail', {categoryName: game.name})}
              // sliderSize={(119 / 351) * 100}
              contentPaddingPosition={'slider'}
              renderItem={item => (
                <CasinoGamesCard
                  onClick={() => toAllCasinoGame(item.id)}
                  width="7rem"
                  height="9.25rem"
                  key={item.id}
                  title={item.name}
                  desc={item.provider}
                  pic={item.gamePic}
                  odds={item.odds}
                  onlyPic
                />
              )}
            />
          ) : null,
        )
      ) : (
        <div className="h-[18rem]">
          <NoData />
        </div>
      )}
      <Footer />
      <div className="h-16" />
    </div>
  );
};

export default CasinoContent;
