import React, {FC, useMemo} from 'react';
import {Header} from '@/components/v2/business/category/category';
import {useTranslation} from 'react-i18next';
import {Image} from 'antd-mobile';
import {useRequest} from 'ahooks';
import {toPriceStr} from '@/utils';
import {getIGameUserRanking} from '@/pages/casino/casino.service';
interface TodayEarningChartItemProps {
  avatar?: string;
  name?: string;
  top?: number;
  earning?: string;
}

const TodayEarningChartItem: FC<TodayEarningChartItemProps> = ({
  avatar,
  name,
  top,
  earning,
}) => {
  const topImage = useMemo(() => {
    const imageMap = [
      '',
      require('@assets/imgs/home.07/win-top-1.webp'),
      require('@assets/imgs/home.07/win-top-2.webp'),
      require('@assets/imgs/home.07/win-top-3.webp'),
    ];
    return top ? imageMap[top] || '' : '';
  }, [top]);
  const topColor = useMemo(() => {
    const colorMap = ['', '#FBAE3B', '#96AAAF', '#96AAAF'];
    return top ? colorMap[top] || '' : '';
  }, [top]);
  return (
    <div className="flex flex-row items-center justify-between text-t1 text-xs py-2">
      <div className="flex flex-row gap-3">
        <Image
          src={avatar}
          width="2.5rem"
          height="2.5rem"
          className="rounded-full"
        />
        <div className="flex flex-col">
          <span>{name || '-'}</span>
          {!!top && (
            <div className="flex flex-row items-center gap-1">
              <Image src={topImage} width="1.5rem" height="1.5rem" />
              <span className="text-sm" style={{color: topColor}}>
                NO{top}
              </span>
            </div>
          )}
        </div>
      </div>
      <span className="font-bold">{earning}</span>
    </div>
  );
};

const TodayEarningChart = () => {
  const {t} = useTranslation();
  const {data} = useRequest(() => getIGameUserRanking(2, 3));
  return data && data.length ? (
    <div className={'flex flex-col gap-3'}>
      <Header
        icon={require('@assets/imgs/home.07/winning-information.webp')}
        title={t('home.title.todayEarningsChart')}
      />
      <div className="px-3 flex flex-col">
        <div className="p-3 flex flex-col divide-y-[.0625rem] divide-solid divide-line bg-card rounded-lg">
          {data?.map((item, index) => (
            <TodayEarningChartItem
              key={index}
              name={item.userName}
              top={item.ranking}
              avatar={item.userAvatar}
              earning={toPriceStr(item.bonus)}
            />
          ))}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
export default TodayEarningChart;
