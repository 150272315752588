import React, {useState} from 'react';
import {IUserInfo} from '@/services/global.service';
import {goTo, toPriceStr} from '@/utils';
import cls from './me.module.scss';
import {Image} from 'antd-mobile';
import refreshIcon from '@/assets/icons/me/refresh.07.svg';
import eyeIcon from '@/assets/icons/me/eye.svg';
import eyeOffIcon from '@/assets/icons/me/eye-off.svg';
import {ReactComponent as ArrowRoundSvg} from '@/assets/svgs/arrow-round.svg';
import walletIcon from '@/assets/icons/me/wallet.webp';

export interface MeAmountProps {
  login: boolean;
  user?: IUserInfo;
  onRefresh?: () => void;
}

const MeAmount: React.FC<MeAmountProps> = ({login, user, onRefresh}) => {
  const [showPrice, setShowPrice] = useState(false);
  const toRecharge = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    // 跳转recharge
    goTo('Recharge');
  };
  return (
    <div className={cls.amount}>
      <div className={cls.amountleft}>
        <div className={cls.amountTitle}>
          <Image className={cls.amountIcon} src={walletIcon} />
          <span className={cls.amountText}>Total wallet</span>
          <Image
            src={showPrice ? (eyeIcon as any) : (eyeOffIcon as any)}
            className={cls.eyesIcon}
            onClick={() => setShowPrice(!showPrice)}
          />
        </div>
        <div className={cls.amountValue}>
          <div onClick={toRecharge}>
            {user && showPrice
              ? '₺ ' +
                toPriceStr(user.userBalance, {
                  thousands: true,
                  showCurrency: false,
                })
              : '*******'}
          </div>
          <ArrowRoundSvg className="size-4 -rotate-90 text-t1" />
        </div>
      </div>
      <Image
        onClick={onRefresh}
        src={refreshIcon as any}
        className={cls.refreshIcon}
      />
    </div>
  );
};

export default MeAmount;
