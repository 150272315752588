import React, {useState, useEffect} from 'react';
import {Popup, Image} from 'antd-mobile';
import globalStore from '@/services/global.state';
import {MessageCountInfo} from '@/services/global.service';
import {goTo} from '@/utils';
import MenuIcons from './icons';
import MenuItem from './menu-item';
import './home-menu.module.scss';
import {BasicObject} from '@/types';
import {useLanguageModal} from '@/components/business/language';
import {languagesMap} from '@/i18n';
import {useTranslation} from 'react-i18next';
import {ReactComponent as PopupCloseSvg} from '@assets/svgs/popup-close.svg';
import {ReactComponent as ArrowRoundSvg} from '@assets/svgs/arrow-round.svg';
const menuBanner = require('@/assets/imgs/home.07/menu-banner.webp');
const isCloseSports = false;

interface IHomeMenu {
  isOpen: boolean;
  close: () => void;
  noticeMap: BasicObject;
  spinShow: () => void;
}
const HomeMenu = ({isOpen, close, noticeMap, spinShow}: IHomeMenu) => {
  const {t} = useTranslation();
  const [messageCountInfo, setMessageCountInfo] = useState<MessageCountInfo>({
    messageTotalCount: 0,
    sysMessageCount: 0,
    sysUserMessageCount: 0,
  });
  const {renderModal, show} = useLanguageModal();
  useEffect(() => {
    const msgSub = globalStore.notificationSubject.subscribe(_countInfo => {
      setMessageCountInfo(
        _countInfo || {
          messageTotalCount: 0,
          sysMessageCount: 0,
          sysUserMessageCount: 0,
        },
      );
    });
    return () => {
      msgSub.unsubscribe();
    };
  }, []);
  const midMenus = [
    'Casino',
    'Slots',
    'Live casino',
    'Lottery',
    'Sports',
    'Promotions',
  ];
  const midMenusHandlers: any = {
    Casino: () => {
      goTo('Casino');
    },
    Slots: () => {
      goTo('CasinoDetail', {categoryName: 'Slots'}); //TODO
    },
    'Live casino': () => {
      goTo('CasinoDetail', {categoryName: 'Live Casino'});
    },
    Lottery: () => {
      goTo('Lottery');
      // goTo('CasinoDetail', {categoryName: 'Lottery'});
    },
    Sports: () => {
      if (!isCloseSports) {
        globalStore.openSports.next(undefined);
      } else {
        globalStore.globalTotal.next({
          type: 'warning',
          message: t('warning.unopen'),
        });
      }
    },
    Promotions: () => {
      goTo('Promotions');
    },
  };

  return (
    <Popup
      position="left"
      bodyStyle={{
        width: '17.5rem',
        background: 'var(--card)',
        padding: '2.5rem 0.75rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        overflow: 'auto',
      }}
      visible={isOpen}
      closeOnMaskClick
      onClose={close}>
      <div className="flex flex-row py-3 justify-between items-center">
        <Image
          width={'auto'}
          height={'1.25rem'}
          src={require('@assets/imgs/home.07/top-logo.webp')}
        />
        <div className="size-6" onClick={close}>
          <PopupCloseSvg className="text-t3 size-6" />
        </div>
      </div>
      <div
        className="w-full relative"
        onClick={() => {
          close();
          if (globalStore.token) {
            goTo('Invitation');
          } else {
            goTo('Login');
          }
        }}>
        <img src={menuBanner} className="w-full" />
        <div className="absolute top-[1.125rem] left-[6.5rem] flex flex-col tracking-[-0.01875rem]">
          <span className="text-t1 text-sm font-bold capitalize">
            Become an agent
          </span>
          <span className="text-t1 text-[.625rem] capitalize">
            earn super high commissions.
          </span>
        </div>
      </div>
      <MenuItem
        onClick={() => {
          spinShow();
          close();
        }}
        icon={<MenuIcons.LuckySpin />}
        title="Lucky Spin"
        rightEle={
          noticeMap.LUCKY_SPIN ? (
            <div className="w-5 h-5 font-bold text-white text-[0.625rem] rounded-full flex items-center justify-center bg-[#E40000]">
              {noticeMap.LUCKY_SPIN}
            </div>
          ) : null
        }
      />
      <div className="flex flex-col gap-0.5">
        {midMenus.map(title => {
          const Icon = (MenuIcons as any)[title];
          return (
            <MenuItem
              onClick={() => {
                midMenusHandlers[title]();
                close();
              }}
              key={title}
              icon={<Icon className="size-6 text-t2" />}
              title={title}
            />
          );
        })}
      </div>
      <MenuItem
        onClick={() => {
          close();
          goTo('Notification');
        }}
        icon={<MenuIcons.Note className="size-6 text-t2" />}
        title="Notifications"
        rightEle={
          messageCountInfo?.messageTotalCount ? (
            <div className="w-5 h-5 font-bold text-white text-[0.625rem] rounded-full flex items-center justify-center bg-[#E40000]">
              {messageCountInfo?.messageTotalCount}
            </div>
          ) : null
        }
      />
      <MenuItem
        onClick={show}
        icon={<MenuIcons.Lang className="size-6 text-t2" />}
        title={languagesMap[globalStore.lang]}
        rightEle={
          <div>
            <ArrowRoundSvg className="size-3 text-t3" />
          </div>
        }
      />
      {renderModal}
    </Popup>
  );
};

export default HomeMenu;
