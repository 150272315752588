import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styles from './home.module.scss';
import {
  getBannerList,
  getHomeGoldGames,
  getUserGameInfo,
  postRankingList,
} from './home.service';
import {useRequest} from 'ahooks';

import Banner from './component/banner';
import CategoryTab from './component/categoryTab';
import SysMessage from './component/SysMessage';
import HomeHeader from './component/home-header';
// import {getGameModal} from '@/components/utils/gameModal';
import HomeMenu from './component/home-menu';
import {BasicObject} from '@/types';
import {getNoticeCheck} from './home.service';
import globalStore from '@/services/global.state';
import {useLuckySpinModal} from '@/common-pages/luckyspin/luckyspin.hooks';
import WinningInformation from './component/winning-information';
import TodayEarningChart from './component/today-earning-chart';
import Footer from '@/components/v2/business/footer/footer';
import HomeModal from './component/home-modal';
import {checkPops, IListEle} from '@/app.service';
import HotContent from './hot/hot-content';
import SlotsGamesContent from './other-games/slot-games-content';
import HomeSide, {useTop} from '@/components/v2/business/homeside';
import CategoryContent from '../casino/category/content';
import Spin from '@/components/v2/basic/spin';
import CasinoContent from '../casino/content';

import {
  postSystemMessageList,
  NotificationItem,
} from '@/common-pages/notification/notification.service';
import {useLogin} from '@/utils/state-hooks';
import {getUUID, goTo} from '@/utils';
import RecentBigWins from './component/recent-big-wins';
import {UserGameInfo, WinnerItem} from './home.type';
import {GAME_TOPIC, useStompClient} from '@/components/utils/stomp-client';

export default function Home() {
  const {data: banners, loading} = useRequest(getBannerList);

  const {data: categoryTabs, loading: categoryTabsLoading} =
    useRequest(getHomeGoldGames);

  const {data: rankingData, loading: rankingLoading} =
    useRequest(postRankingList);

  const [tabKey, setTabKey] = useState('');
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [noticeMap, setNoticeMap] = useState<BasicObject>({
    FREE_LOTTERY: 0,
    REBATE: 0,
    LUCKY_SPIN: 0,
  });
  const [modalList, setModalList] = useState<IListEle[]>([]);
  const [sysMessage, setSysMessage] = useState<NotificationItem>();
  const login = useLogin();
  const getList = async () => {
    const res = await checkPops();
    setModalList(res);
  };
  const [categoryLoading, setCategoryLoading] = useState(false);
  const {parentRef, hasTop, onTop} = useTop<HTMLDivElement>();

  const doNotice = useCallback(
    (token: string | null) => {
      if (token) {
        getNoticeCheck().then(noticeList => {
          const newNoticeMap: BasicObject = {};
          Object.keys(noticeMap).forEach(key => {
            const notice = noticeList.find(item => item.noticeKey === key);
            if (notice) {
              newNoticeMap[key] = notice.noticeCount;
            }
          });
          setNoticeMap(newNoticeMap);
        });
      } else {
        setNoticeMap({
          FREE_LOTTERY: 0,
          REBATE: 0,
          LUCKY_SPIN: 0,
        });
      }
    },
    [noticeMap],
  );
  const {renderModal: renderSpin, show: spinShow} = useLuckySpinModal({
    onNotice: () => doNotice(globalStore.token),
  });

  useEffect(() => {
    if (categoryTabs) {
      setTabKey(categoryTabs[0].moduleType);
    }
  }, [categoryTabs]);
  useEffect(() => {
    doNotice(globalStore.token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalStore.token]);
  useEffect(() => {
    if (login) {
      postSystemMessageList(1).then(r => {
        setSysMessage(r.content[0]);
      });
    }

    getList();
  }, [login]);

  const handleCategoryTabChange = (_tabKey: string) => {
    if (_tabKey.toLowerCase().includes('sports')) {
      globalStore.openSports.next(undefined);
      return;
    }
    if (_tabKey.toLowerCase().includes('lottery')) {
      goTo('Lottery');
      return;
    }
    setTabKey(_tabKey);
  };

  const [winInfoList, setWinInfoList] = useState<(WinnerItem & {id: string})[]>(
    [],
  );

  useEffect(() => {
    const nowWinInfoList = localStorage.getItem('winInfoList');
    if (nowWinInfoList) {
      try {
        setWinInfoList(JSON.parse(nowWinInfoList));
      } catch (e) {
        console.log('parse winInfoList error');
      }
    }
    getUserGameInfo().then(res => {
      let anotherList: (WinnerItem & {id: string})[] = res
        .map(_item => {
          try {
            return {
              bonus: _item.wonAmount,
              gameName: _item.gameName,
              gamePic: _item.gameImg,
              gameType: _item.gameType,
              userName: _item.userName,
              userPhone: _item.userPhone,
              userAvatar: _item.userAvatar,
              userLevel: _item.level,
              id: getUUID(),
            };
          } catch (e) {
            return null;
          }
        })
        .filter(item => item != null && item.bonus > 0) as (WinnerItem & {
        id: string;
      })[];
      setWinInfoList(list => [...anotherList, ...list]);
    });
  }, []);

  useEffect(() => {
    if (!winInfoList.length) {
      return;
    }
    let nowWinInfoList = winInfoList;
    if (winInfoList.length > 30) {
      nowWinInfoList = winInfoList.slice(0, 30);
    }
    setWinInfoList(nowWinInfoList);
  }, [winInfoList]);

  const stompItem = useMemo(
    () => [
      {
        topic: GAME_TOPIC,
        onSubscribe: (message: any[]) => {
          let anotherList: (WinnerItem & {id: string})[] = message
            .map(item => {
              try {
                const _item = JSON.parse(item) as UserGameInfo;
                return {
                  bonus: _item.wonAmount,
                  gameName: _item.gameName,
                  gamePic: _item.gameImg,
                  gameType: _item.gameType,
                  userName: _item.userName,
                  userPhone: _item.userPhone,
                  userAvatar: _item.userAvatar,
                  userLevel: _item.level,
                  id: getUUID(),
                };
              } catch (e) {
                return null;
              }
            })
            .filter(item => item != null && item.bonus > 0) as (WinnerItem & {
            id: string;
          })[];
          setWinInfoList(list => [...anotherList, ...list]);
        },
      },
    ],
    [],
  );

  const biggestList = useMemo(() => {
    return (
      rankingData?.biggestList.map(item => ({...item, id: getUUID()})) || []
    );
  }, [rankingData]);

  useStompClient(stompItem);

  return (
    <div className={`${styles.page} flex flex-col overflow-hidden home-page`}>
      <HomeHeader openMenu={() => setIsOpenMenu(true)} />
      <div className="flex flex-col flex-1 overflow-auto gap-2" ref={parentRef}>
        {loading || categoryTabsLoading ? null : (
          <>
            {banners && banners.length ? (
              <div className="flex flex-col pt-3 px-3 gap-3 flex-none">
                <Banner list={banners || []} />
              </div>
            ) : null}
            {!!sysMessage && <SysMessage sysMessage={sysMessage} />}
            {!rankingLoading && !!rankingData?.biggestList?.length && (
              <RecentBigWins list={biggestList} />
            )}

            <div
              className={`px-3 py-2 sticky top-0 z-20 ${styles['category-tab']}`}>
              <CategoryTab
                activeKey={tabKey}
                onChange={handleCategoryTabChange}
                tabs={categoryTabs || []}
              />
            </div>
            <div className="flex flex-col gap-5">
              {!!tabKey &&
                (tabKey.toLowerCase().includes('hot') ? (
                  <>
                    <HotContent onTab={setTabKey} />
                    <div className="flex flex-col gap-3">
                      {!!winInfoList?.length && (
                        <WinningInformation list={winInfoList} />
                      )}

                      <TodayEarningChart />
                      <Footer />
                    </div>
                  </>
                ) : tabKey.toLowerCase().includes('slots') ? (
                  <>
                    <SlotsGamesContent tabKey={tabKey} />
                    <Footer />
                  </>
                ) : tabKey
                    .toLowerCase()
                    .includes('lottery') ? null : tabKey.toLowerCase() ===
                  'casino' ? (
                  <Spin loading={categoryLoading}>
                    <CasinoContent onLoading={setCategoryLoading} />
                  </Spin>
                ) : (
                  <Spin loading={categoryLoading}>
                    <CategoryContent
                      hideCategories
                      category={tabKey}
                      filterClass="top-12"
                      onLoading={setCategoryLoading}
                      onReloadList={onTop}
                    />
                  </Spin>
                ))}

              <div className="h-16" />
            </div>
          </>
        )}
      </div>
      <HomeSide hasTop={hasTop} onTop={onTop} />
      <HomeMenu
        isOpen={isOpenMenu}
        close={() => setIsOpenMenu(false)}
        noticeMap={noticeMap}
        spinShow={spinShow}
      />
      <HomeModal list={modalList} />
      {renderSpin}
    </div>
  );
}
