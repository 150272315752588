import React, {useState, useEffect} from 'react';
import {Mask, Swiper, Image} from 'antd-mobile';
import Icons from './icons';
import {IListEle, tokenRoutes} from '@/app.service';
import globalStore from '@/services/global.state';
import {getUrlParams, goTo} from '@utils';
import {goToUrl} from '@/common-pages/game-navigate';

const HomeModal = ({list}: {list: IListEle[]}) => {
  const [visible, setVisible] = useState(list.length > 0);
  useEffect(() => {
    if (list.length > 0) {
      setVisible(true);
    }
  }, [list]);
  return (
    <Mask
      visible={visible}
      onMaskClick={() => setVisible(false)}
      className="flex items-center justify-center">
      <div className="relative w-[21.9375rem] h-[29.625rem]">
        <div
          onClick={() => setVisible(false)}
          className="absolute right-0 top-[-2.75rem]">
          <Icons.Close />
        </div>
        <Swiper
          autoplay
          loop
          style={{
            '--track-padding': ' 0 0 1.7rem',
          }}
          indicatorProps={{
            style: {
              '--dot-color': 'rgba(255, 255, 255, 0.50)',
              '--active-dot-color': '#E75300',
              '--dot-size': '0.5rem',
              '--active-dot-size': '0.5rem',
              '--dot-border-radius': '50%',
              '--active-dot-border-radius': '50%',
              '--dot-spacing': '0.5rem',
            },
          }}>
          {list.map((item, index) => (
            <Swiper.Item key={index}>
              <div
                className="w-full rounded-2xl overflow-hidden bg-card"
                onClick={() => {
                  if (item.popUrl.startsWith('route:')) {
                    const [route, popParams] = item.popUrl.slice(6).split('?');
                    const needToken = tokenRoutes.some(r =>
                      route.startsWith(r),
                    );
                    if (!needToken || (needToken && globalStore.token)) {
                      goTo(route, getUrlParams(popParams));
                    } else {
                      goTo('Login', {
                        sucessPage: route,
                        sucessPageParams: popParams,
                      });
                    }
                  } else if (item.popUrl.startsWith('http')) {
                    window.open(item.popUrl);
                  } else {
                    item.popUrl && goToUrl(item.popUrl);
                  }
                  setVisible(false);
                }}>
                <Image
                  width={'21.9375rem'}
                  height={'29.625rem'}
                  src={item.popImg}
                  fit="cover"
                />
              </div>
            </Swiper.Item>
          ))}
        </Swiper>
      </div>
    </Mask>
  );
};

export default HomeModal;
