'use client';
import React from 'react';
import style from './lucky-spin.module.scss';

const LuckySpin = () => {
  return (
    <div className="relative">
      <div className="bg">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <circle
            id="Ellipse 873"
            cx="10.06"
            cy="10.1266"
            r="9.60001"
            fill="url(#paint0_linear_3963_6655)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3963_6655"
              x1="10.06"
              y1="0.526611"
              x2="10.06"
              y2="19.7266"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFCF74" />
              <stop offset="0.736618" stopColor="#FFF06B" />
              <stop offset="1" stopColor="#D4A235" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div
        className={`${style.rotateSpin} absolute left-[0.0625rem] top-[0.0625rem]`}>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g id="Group 1000004328">
            <path
              id="Ellipse 874"
              d="M17.8539 9.45468C17.7846 11.3118 17.1292 13.0993 15.9816 14.561L9.06002 9.12667L17.8539 9.45468Z"
              fill="#691DDF"
              stroke="#FFECAD"
              strokeWidth="0.266667"
            />
            <path
              id="Ellipse 875"
              d="M17.8542 9.44636C17.9217 7.5892 17.3996 5.75835 16.3626 4.2162L9.06002 9.12667L17.8542 9.44636Z"
              fill="#25B6C7"
              stroke="#FFECAD"
              strokeWidth="0.266667"
            />
            <path
              id="Ellipse 876"
              d="M16.3587 4.21041C15.3205 2.66908 13.821 1.496 12.0751 0.859282L9.06002 9.12667L16.3587 4.21041Z"
              fill="#B425DF"
              stroke="#FFECAD"
              strokeWidth="0.266667"
            />
            <path
              id="Ellipse 877"
              d="M12.0698 0.857365C10.3235 0.221762 8.4208 0.155319 6.63441 0.667558L9.06002 9.12667L12.0698 0.857365Z"
              fill="#E2981A"
              stroke="#FFECAD"
              strokeWidth="0.266667"
            />
            <path
              id="Ellipse 878"
              d="M6.63049 0.668684C4.84433 1.18175 3.26646 2.24711 2.123 3.71206L9.06002 9.12667L6.63049 0.668684Z"
              fill="#E42461"
              stroke="#FFECAD"
              strokeWidth="0.266667"
            />
            <path
              id="Ellipse 879"
              d="M2.12552 3.70884C0.981385 5.17327 0.330227 6.96231 0.26537 8.81956L9.06002 9.12667L2.12552 3.70884Z"
              fill="#BAAF16"
              stroke="#FFECAD"
              strokeWidth="0.266667"
            />
            <path
              id="Ellipse 880"
              d="M0.265204 8.82437C0.201365 10.6817 0.727142 12.5115 1.76718 14.0516L9.06002 9.12667L0.265204 8.82437Z"
              fill="#7223EC"
              stroke="#FFECAD"
              strokeWidth="0.266667"
            />
            <path
              id="Ellipse 881"
              d="M1.76448 14.0476C2.80367 15.5882 4.30393 16.7604 6.05024 17.396L9.06002 9.12667L1.76448 14.0476Z"
              fill="#26B3C4"
              stroke="#FFECAD"
              strokeWidth="0.266667"
            />
            <path
              id="Ellipse 882"
              d="M6.04994 17.3959C7.79623 18.0315 9.69892 18.098 11.4853 17.5859L9.06002 9.12667L6.04994 17.3959Z"
              fill="#C231E5"
              stroke="#FFECAD"
              strokeWidth="0.266667"
            />
            <path
              id="Ellipse 883"
              d="M11.4473 17.5967C13.236 17.0925 14.8192 16.0351 15.9699 14.5758L9.06002 9.12667L11.4473 17.5967Z"
              fill="#F1A71A"
              stroke="#FFECAD"
              strokeWidth="0.266667"
            />
          </g>
        </svg>
      </div>
      <div className="outer absolute left-[0.0625rem] top-[0.0625rem]">
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g id="Ellipse 890" filter="url(#filter0_f_3963_6677)">
            <circle
              cx="9.06"
              cy="9.12665"
              r="7.73334"
              stroke="white"
              strokeWidth="0.533334"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_3963_6677"
              x="-0.0066092"
              y="0.0600412"
              width="18.1333"
              height="18.1333"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="0.533334"
                result="effect1_foregroundBlur_3963_6677"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div className="up absolute top-[-0.17rem] left-[-0.07rem]">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g id="Group 1000004329">
            <path
              id="Subtract"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.3522 3.81758C14.3521 3.81757 14.3521 3.81755 14.3521 3.81754C12.3964 3.10754 10.359 3.08164 8.50206 3.61738L7.77034 1.07346C10.2298 0.366026 12.8502 0.455378 15.2557 1.32871L14.3522 3.81758Z"
              fill="#0F969C"
            />
            <path
              id="Ellipse 886"
              d="M15.2557 1.3289C12.8501 0.45557 10.2298 0.366217 7.7703 1.07365L11.1202 12.7201L15.2557 1.3289Z"
              fill="url(#paint0_radial_3963_6667)"
            />
            <g id="Ellipse 884" filter="url(#filter0_d_3963_6667)">
              <mask id="path-3-inside-1_3963_6667" fill="white">
                <path d="M15.2557 1.3289C12.8501 0.45557 10.2298 0.366217 7.7703 1.07365L11.1202 12.7201L15.2557 1.3289Z" />
              </mask>
              <path
                d="M15.2557 1.3289C12.8501 0.45557 10.2298 0.366217 7.7703 1.07365L11.1202 12.7201L15.2557 1.3289Z"
                stroke="url(#paint1_linear_3963_6667)"
                strokeWidth="1.33333"
                shapeRendering="crispEdges"
                mask="url(#path-3-inside-1_3963_6667)"
              />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_3963_6667"
              x="6.7036"
              y="-0.198438"
              width="9.61869"
              height="14.252"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="0.266667" />
              <feGaussianBlur stdDeviation="0.533334" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_3963_6667"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_3963_6667"
                result="shape"
              />
            </filter>
            <radialGradient
              id="paint0_radial_3963_6667"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(11.4342 5.80181) rotate(-93.6763) scale(4.81008 3.97464)">
              <stop stopColor="white" stopOpacity="0" />
              <stop offset="0.505042" stopColor="white" stopOpacity="0" />
              <stop offset="1" stopColor="white" stopOpacity="0.59" />
            </radialGradient>
            <linearGradient
              id="paint1_linear_3963_6667"
              x1="8.38439"
              y1="0.885416"
              x2="11.3082"
              y2="12.2023"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#F4CC3D" />
              <stop offset="0.431932" stopColor="#FFE175" />
              <stop offset="1" stopColor="#D4AA13" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="center absolute top-[0.4125rem] left-[0.3875rem]">
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g id="Group 1000004330">
            <g id="Ellipse 887" filter="url(#filter0_d_3963_6673)">
              <circle
                cx="4.05998"
                cy="3.12663"
                r="2.66667"
                fill="url(#paint0_linear_3963_6673)"
              />
              <circle
                cx="4.05998"
                cy="3.12663"
                r="2.4"
                stroke="url(#paint1_linear_3963_6673)"
                strokeWidth="0.533334"
              />
            </g>
            <path
              id="Ellipse 888"
              d="M5.39348 2.05998C5.39348 2.35453 4.79652 2.59331 4.06014 2.59331C3.32376 2.59331 2.72681 2.35453 2.72681 2.05998C2.72681 1.76543 3.32376 1.39331 4.06014 1.39331C4.79652 1.39331 5.39348 1.76543 5.39348 2.05998Z"
              fill="url(#paint2_linear_3963_6673)"
            />
            <path
              id="Ellipse 889"
              opacity="0.4"
              d="M5.39348 4.32664C5.39348 4.62119 4.79652 4.9932 4.06014 4.9932C3.32376 4.9932 2.72681 4.62119 2.72681 4.32664C2.72681 4.03209 3.32376 4.45987 4.06014 4.45987C4.79652 4.45987 5.39348 4.03209 5.39348 4.32664Z"
              fill="url(#paint3_linear_3963_6673)"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_3963_6673"
              x="0.326643"
              y="0.459961"
              width="7.46659"
              height="7.46659"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1.06667" />
              <feGaussianBlur stdDeviation="0.533334" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_3963_6673"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_3963_6673"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_3963_6673"
              x1="4.05998"
              y1="0.459961"
              x2="4.05998"
              y2="5.7933"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#8900ED" />
              <stop offset="0.679763" stopColor="#A932FF" />
              <stop offset="1" stopColor="#8400E3" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_3963_6673"
              x1="4.05998"
              y1="0.459961"
              x2="4.05998"
              y2="5.7933"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFE600" />
              <stop offset="1" stopColor="#EABC91" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_3963_6673"
              x1="4.06014"
              y1="1.39331"
              x2="4.06014"
              y2="2.59331"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFF4F4" />
              <stop offset="1" stopColor="#FFF4F4" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_3963_6673"
              x1="4.06014"
              y1="4.85986"
              x2="4.06014"
              y2="4.45986"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFF4F4" />
              <stop offset="1" stopColor="#FFF4F4" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default LuckySpin;
