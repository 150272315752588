export default {
  loading: 'Loading...',
  'no-update': 'Already the latest version',
  'load-more': 'Load More',
  'copy-success': 'Copy Successfully',
  'invalid-url': 'Invalid Url',
  dicePopular: 'The most popular dice game',
  indianLottery: 'Indian official lottery',
  jackpot: 'JACKPOT',
  label: {
    allUpp: 'ALL',
    bonus: 'Bonus',
    play: 'Play Now',
    casino: 'Casino',
    balance: 'Balance',
    topUpp: 'TOP',
    allGames: 'All Games',
  },
  category: {
    label: {
      odds: 'Odds of',
      lotteryTitle: 'Dragon assistant',
      lotteryContent: '5 Consecutive Periods with theSame Result',
      superJackpot: 'Super Jackpot',
      saba: 'SABA Sports',
      categories: 'Categories',
      provider: 'Providers',
      default: 'Default',
      popular: 'Popular',
    },
  },
  gameModal: {
    tip: {
      choose: 'Choose Your Game Mode',
      desc1: "Choose 'Free Play' to explore the game",
      desc2: "without real money, or select 'Start Game'",
      desc3: 'for the complete experience.',
    },
    label: {
      start: 'Start Game',
      free: 'Free Play',
    },
  },
  footer: {
    tip: {
      download: 'Download the app for the best Indibet experience.',
      intro1: 'durjagame Mainly Features Online Video Games, Slots and Casino.',
      intro2:
        'We work with more than 10,000 video games, slot and casino providers, all of whom are certified as fair gambling, support fast deposits and withdrawals, and friendly service. Gambling Is Addictive, Please Play It Wisely. POP888 Only Accepts Customers over the Age of 18.',
    },
  },
  home: {
    label: {
      login: 'LOG IN',
      register: 'REGISTER',
      menu: 'Menu',
      viewAll: 'View All',
      buyNow: 'Buy Now',
      'lucky-spin': 'Lucky Spin',
      rebate: 'Rebate',
      'free-lottery': 'Free Lottery',
      referral: 'Referral',
      'refer-friend': 'Refer Friend',
      agent: 'Agent',
      biggestWins: 'Biggest Wins',
      recentBigWins: 'Recent Big Wins',
      jackpotGames: 'Jackpot Games',
      onlineUser: '{{number}} users playing',
      popularGame: 'The most popular game',
      provedores: 'Provedores',
      allGames: 'All Games',
      more: 'More',
      days: 'Days',
      hours: 'Hours',
      minutes: 'Minutes',
      seconds: 'Seconds',
      total: 'Total',
      bet: 'Bet',
      online: 'Online',
      winningAmount: 'Winning Amount',
      imgFail: 'Image failed to load',
      nextDraw: 'Next Draw Starts in',
      quickType: 'Quick Type',
      betNow: 'Bet Now',
    },
    tab: {
      home: 'Home',
      result: 'Result',
      casino: 'Casino',
      scratch: 'Scratch',
      scratchOff: 'Scratch Off',
      referral: 'Referral',
      me: 'Me',
      live: 'Live',
      liveCasino: 'Live Casino',
      sports: 'Sports',
      lottery: 'Lottery',
      promotions: 'Promotions',
      invite: 'Invite',
      wallet: 'Wallet',
      proxyhome: 'Agent',
    },
    scratch: {
      title: 'Scratch',
      free: 'FREE',
    },
    kerala: {
      title: 'Kerala State Lottery',
      no: 'NO.',
      lastResult: 'Last Jackpot Outcome',
    },
    digit: {
      title: '3 Digit Game',
      booking: 'Time for Next Booking',
      ticket: 'Ticket',
      price: 'Ticket Price',
      lastResult: 'Last Draw Outcome',
      drawTime: 'Next Draw Time',
    },
    dice: {
      title: 'Dice Game',
      play: 'Play Now',
    },
    color: {
      title: 'Color Prediction',
    },
    matka: {
      bid: 'BID',
      win: 'WIN',
      OPEN: 'OPEN',
      CLOSE: 'CLOSE',
      open: 'Open',
      close: 'Close',
      title: 'Satta Matka',
    },
    'live-casino': {
      title: 'Live Casino',
    },
    car: {
      title: 'Quick Race',
      min: 'min',
      text: 'Next draw starts in',
    },
    tip: {
      closed: '{{name}} is closed now, please choose next game',
      copied: 'Copy successfully, please share with friends',
      original:
        'The Internal Game ls Independently Developed by the PoP888Team, Which ls Fun, Fair and Safe',
    },

    luckyspin: {
      add: 'Add',
      spin: 'SPIN',
      won: 'Won',
      winner: 'Big Winners',
      mySpin: 'My Spin',
    },
    title: {
      scratchOff: 'Scratch Off',
      casino: 'Casino',
      live: 'Live',
      sports: 'Sports',
      lottery: 'Lottery',
      scratch: 'Scratch',
      servertime: 'Server Uptime Days',
      winningInformation: 'Winning Information',
      todayEarningsChart: "Today's earnings chart",
    },
  },
  casino: {
    sortby: 'Sort by',
    categories: 'Categories',
    popular: 'Popular',
    all: 'All',
    lobby: 'Lobby',
  },
  me: {
    label: {
      luckyspin: 'Luckyspin',
    },
    webview: {
      vip: 'Member Center',
    },
    new: 'NEW',
    user: {
      userId: 'User ID',
      moreExciting: 'More exciting after logging in',
      login: 'Log in',
      loginUpper: 'LOGIN',
      uid: 'UID:',
      lastLogin: 'Last login:',
    },
    money: {
      totalWallet: 'Total Wallet',
      myWallet: 'My Wallet',
      cashBalance: 'Cash Balance',
      withdrawAmount: 'Withdrawable Amount',
      totalBalance: 'Total balance',
      wallet: 'Wallet',
      recharge: 'Recharge',
      withdraw: 'Withdraw',
    },
    vip: {
      recharge: 'Recharge',
      move: 'more to reach level',
    },
    bottom: {
      recharge: 'Recharge',
      withdraw: 'Withdraw',
      transfer: 'Transfer',
      proxy: 'Agency Center',
      logout: 'LOG OUT',
      resultHistory: 'Result history',
      myBets: 'My Bets',
      myTransactions: 'My Transactions',
      commission: 'My Commission',
      notify: 'Notifications',
      lang: 'Languages',
      customer: 'Customer service',
      update: 'Update',
      password: 'Change Password',
    },
    language: {
      switch: 'Switch Language',
      english: 'English',
    },
    tip: {
      cashTitle: 'Cash Balance:',
      cashContent:
        '"Cash balance" refers to the actual cash funds available in your account that can be used for betting or other transactions.',
      withdrawTitle: 'Withdrawable Amount:',
      withdrawContent:
        '"Withdrawable amount" is the portion of your cash balance that can be withdrawn from your account. It represents the money that has met the wagering requirements, which need to be fulfilled before the funds can be withdrawn.',
      recharge: 'Add funds to an account',
      withdraw: 'Withdraw to a bank card',
      transfer: 'Convert to Cash Balance',
      luckyspin: "Don't miss out on the cash lottery",
      vip: 'Membership\nBenefits',
    },
  },
  invitation: {
    title: {
      singUp: 'Free lottery tickets',
    },
    label: {
      claim: 'Claim your Ticket',
    },
  },
};
