import NavTitle from '@/components/v2/basic/nav-title';
import {ReactComponent as MenuSvg} from '../svgs/menu.svg';
import React, {useMemo} from 'react';
import {Button, Image} from 'antd-mobile';
import {useTranslation} from 'react-i18next';
import style from './home-header.module.scss';
import {useBalance, useLogin, useUserInfo} from '@/utils/state-hooks';
import {goTo, toPriceStr} from '@/utils';
import {defaultHeaderImg} from '@/pages/me/me.variable';

interface IHomeHeader {
  openMenu: () => void;
}
const HomeHeader = ({openMenu}: IHomeHeader) => {
  const {t} = useTranslation();
  const login = useLogin();
  const user = useUserInfo();
  const avatar = useMemo(() => {
    return user?.userAvatar || defaultHeaderImg;
  }, [user]);
  const name = useMemo(() => {
    return user?.userName;
  }, [user]);
  const balance = useBalance();
  return (
    <NavTitle
      hideBack
      leftNode={
        <div className="flex flex-row items-center gap-3">
          <MenuSvg className="w-6 h-6" onClick={openMenu} />
          <Image
            width={'auto'}
            height={'1.25rem'}
            src={require('@assets/imgs/home.07/top-logo.webp')}
          />
        </div>
      }
      title=""
      rightNode={
        !login ? (
          <div className="flex flex-row items-center gap-1 text-t1 font-bold">
            <Button
              shape="rounded"
              onClick={() => goTo('Login')}
              className={`px-3 py-1.5 text-t1 bg-button-big !text-xs ${style['header-button-shadow']}`}>
              {t('home.label.login')}
            </Button>
            <Button
              shape="rounded"
              onClick={() => goTo('SingUp')}
              className={`px-3 py-1.5 text-t1 !text-xs ${style['header-button-gray']}`}>
              {t('home.label.register')}
            </Button>
          </div>
        ) : (
          <div
            className="flex flex-row items-center gap-2"
            onClick={() => goTo('Me')}>
            <div className="flex flex-col items-end">
              <span className="text-t3 text-xs">{name}</span>
              <span className="text-t1 font-bold text-sm">
                {toPriceStr(balance)}
              </span>
            </div>
            <Image
              width={'2rem'}
              height={'2rem'}
              src={avatar || defaultHeaderImg}
              className="rounded-full"
            />
          </div>
        )
      }
    />
  );
};

export default HomeHeader;
