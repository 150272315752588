import React from 'react';
import {Grid, Image} from 'antd-mobile';
import cls from './me.module.scss';
import {goTo} from '@utils';
import {useLuckySpinModal} from '@/common-pages/luckyspin/luckyspin.hooks';
import {useTranslation} from 'react-i18next';

type MeCardProps = {
  login: boolean;
};

function MeCard(props: MeCardProps) {
  const {login} = props;
  const {renderModal, show} = useLuckySpinModal({onNotice: () => {}});
  const localGoTo = (path: string) => {
    if (!login) {
      goTo('Login');
      return;
    }
    goTo(path);
  };
  const {t} = useTranslation();
  const cardList = [
    {
      icon: require('@assets/icons/me/deposit.svg').default,
      title: t('me.bottom.recharge'),
      desc: t('me.tip.recharge'),
      onClick: () => {
        localGoTo('Recharge');
      },
    },
    {
      icon: require('@assets/icons/me/withdraw.svg').default,
      title: t('me.bottom.withdraw'),
      desc: t('me.tip.withdraw'),
      onClick: () => {
        localGoTo('Withdraw');
      },
    },
    {
      icon: require('@assets/icons/me/transfer.svg').default,
      title: t('me.bottom.transfer'),
      onClick: () => localGoTo('Transfer'),
      desc: t('me.tip.transfer'),
    },
  ];
  const cardList2 = [
    {
      icon: require('@assets/icons/me/luckyspan.svg').default,
      title: t('me.label.luckyspin'),
      onClick: show,
      desc: t('me.tip.luckyspin'),
    },
    {
      icon: require('@assets/icons/me/vip.svg').default,
      title: 'VIP',
      onClick: () => localGoTo('Vip'),
      desc: t('me.tip.vip'),
    },
  ];
  return (
    <div className={cls.card}>
      <Grid columns={3} gap={8}>
        {cardList.map(item => {
          return (
            <Grid.Item key={item.title}>
              <div className={cls.cardItem} onClick={item.onClick}>
                <Image src={item.icon} className={cls.icon} />
                <div className={cls.title}>{item.title}</div>
                <div className={cls.desc}>{item.desc}</div>
              </div>
            </Grid.Item>
          );
        })}
      </Grid>
      <Grid columns={2} gap={8} style={{marginTop: 8}}>
        {cardList2.map(item => {
          return (
            <Grid.Item key={item.title}>
              <div className={cls.cardItem2} onClick={item.onClick}>
                <Image src={item.icon} className={cls.icon} />
                <div>
                  <div className={cls.title}>{item.title}</div>
                  <div className={cls.desc}>
                    <div>{item.desc}</div>
                  </div>
                </div>
              </div>
            </Grid.Item>
          );
        })}
      </Grid>
      {renderModal}
    </div>
  );
}

export default MeCard;
