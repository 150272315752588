import {http, indusWinHttp} from '@utils';
import {
  CasinoGameItem,
  CasinoTabsGame,
  GameCategoryParams,
  IGameRangeParams,
  IGameTopItem,
  OtherGameTypeListItem,
} from './casino.type';

export function getIGameUserRanking(gameType: number, count: number = 20) {
  return indusWinHttp.post<IGameRangeParams, IGameTopItem[]>(
    'iGaming/igaming/getTodayGameUserBonusRanking',
    {
      gameType,
      start: 0,
      end: count - 1,
    },
  );
}

export function getDistinctFieldValues(
  fieldName: string,
  categoryId: number = 5,
) {
  return http.get<GameCategoryParams, string[]>(
    'app/homeGames/getDistinctFieldValues',
    {
      params: {
        fieldName,
        categoryId,
      },
    },
  );
}

export function getOtherGameTypeList({
  gameType,
  provider,
  sort = null,
  categoryId = 5,
}: {
  gameType: string;
  sort?: string | null;
  provider?: string;
  categoryId?: number;
}) {
  return http.post<null, OtherGameTypeListItem[]>(
    'app/homeGames/getGameTypeList',
    {
      categoryId,
      isAsc: sort != null ? sort === '1' : undefined,
      gameType,
      provider,
    },
  );
}

export const getCasinoTabs = () => {
  return http.post<{groupCode: 'HOME_CASINO'}, CasinoTabsGame[]>(
    'app/moduleTypes/getAllListBySort',
    {
      groupCode: 'HOME_CASINO',
    },
  );
};

export function getDistinctField(gameType: string, categoryId: number = 5) {
  return http.post<null, string[]>('app/homeGames/getDistinctField', {
    categoryId,
    gameType,
  });
}

export function getCasinoGames() {
  return http.post<null, CasinoGameItem[]>('app/homeGames/allList');
}
