import theme from '@/style';
import React, {FC} from 'react';
import {BasicObject} from '@/types';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {goTo, toAgentApply} from '@/utils';
import {mainPageList} from './main-nav.config';
import style from './main-navigation.module.scss';
const isCloseSports = false;

const Tab = createBottomTabNavigator();

const shadow = {
  startColor: '#0004',
  distance: 1,
  offset: [0, 0],
  style: [
    theme.flex.row,
    theme.flex.around,
    theme.background.white,
    theme.fill.fillW,
    {
      height: 50,
    },
  ] as BasicObject[],
} as BasicObject;

if (globalStore.isWeb) {
  shadow.distance = 0;
  delete shadow.offset;
  shadow.style.push({
    boxShadow: '0 0 1px 0px #0004',
  });
}

const CusDefautTab = ({
  index,
  label,
  isFocused,
  onPress,
}: {
  index: number;
  label: string;
  isFocused: boolean;
  onPress: (isFocused: boolean) => void;
}) => {
  const item = mainPageList[index];
  const activeImg = item.activeImg || item.img;
  const showImg = isFocused ? activeImg : item.img;
  const renderImages = item.middle ? (
    <div className="size-[3.375rem]">
      {item.svgIcon ? (
        item.svgIcon({active: isFocused})
      ) : (
        <div
          className="size-[3.375rem] bg-full"
          style={showImg ? {backgroundImage: `url(${showImg})`} : {}}
        />
      )}
    </div>
  ) : (
    <div className="size-6">
      {item.svgIcon ? (
        item.svgIcon({active: isFocused})
      ) : (
        <div
          className="size-[1.5rem] bg-full"
          style={showImg ? {backgroundImage: `url(${showImg})`} : {}}
        />
      )}
    </div>
  );

  return (
    <div
      onClick={() => onPress?.(isFocused)}
      // testID={options?.tabBarTestID}
      className={`flex flex-col gap-1 items-center ${
        item.middle ? 'w-[3.375rem] z-10' : 'w-[4.5rem]'
      }`}>
      {renderImages}
      <span
        className={`text-xs ${isFocused ? 'font-bold text-c1' : 'text-t2'}`}>
        {label}
      </span>
    </div>
  );
};

interface TabBarListItem {
  name: string;
}
interface TabBarListProps {
  list: TabBarListItem[];
  activeIndex: number;
}

export const TabBarList: FC<TabBarListProps> = ({list, activeIndex}) => {
  return (
    <div
      className={`pb-2 h-[3.75rem] overflow-visible flex flex-row justify-between items-end z-10 ${style['main-nav']}`}>
      {list.map((route, index) => {
        const label = route.name === 'BetBy' ? 'Sports' : route.name;
        const onPress = (isFocused: boolean) => {
          if (isFocused) {
            return;
          }
          if (route.name === 'ProxyHome') {
            if (globalStore.userInfo?.isAgent !== 1) {
              return toAgentApply();
            }
          }
          if (route.name === 'Sports' || route.name === 'BetBy') {
            if (!isCloseSports) {
              globalStore.openSports.next(undefined);
            }

            return;
          }
          // console.log(route.name, '----');
          goTo(route.name);
        };
        return (
          <CusDefautTab
            key={label}
            index={index}
            label={label}
            isFocused={activeIndex === index}
            onPress={onPress}
          />
        );
      })}
    </div>
  );
};
const MainNav = () => {
  const {i18n} = useTranslation();

  return (
    <Tab.Navigator
      // eslint-disable-next-line react/no-unstable-nested-components
      tabBar={props => {
        return (
          <TabBarList
            list={props.state.routes.map(item => ({name: item.name}))}
            activeIndex={props.state.index}
          />
        );
      }}
      screenOptions={{
        headerShown: false,
      }}>
      {mainPageList.map(v => (
        <Tab.Screen
          key={v.name}
          name={v.name}
          component={v.component}
          options={{
            title: i18n.t(
              `home.tab.${
                v.name === 'BetBy' ? 'sports' : v.name.toLocaleLowerCase()
              }`,
            ),
            unmountOnBlur: v.unmountOnBlur,
          }}
          initialParams={v.params}
        />
      ))}
    </Tab.Navigator>
  );
};

export default MainNav;
