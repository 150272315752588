import React, {useMemo} from 'react';
import CasinoTabs, {
  CasinoTabItem,
} from '@/components/v2/business/tabs/casino-tabs';
import {CasinoTabsGame} from '../casino.type';
// import {Image} from 'antd-mobile';

interface CategoryTabProp {
  activeKey: string;
  onChange: (key: string) => void;
  tabs?: CasinoTabsGame[];
  className?: string;
}

export default function CategoryTab({
  tabs,
  activeKey,
  ...otherProps
}: CategoryTabProp) {
  const options = useMemo<CasinoTabItem[]>(() => {
    return (
      tabs?.map(tab => ({
        icon: (
          <div
            className="w-6 h-6 bg-full"
            style={{
              backgroundImage: `url(${
                activeKey === tab.moduleType
                  ? tab.selectedImgUrl || tab.defaultImgUrl
                  : tab.defaultImgUrl
              })`,
            }}
          />
        ),
        title: tab.moduleName,
        tabKey: tab.moduleType,
      })) || []
    );
  }, [tabs, activeKey]);
  return <CasinoTabs options={options} activeKey={activeKey} {...otherProps} />;
}
