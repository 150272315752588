import React from 'react';

interface IMenuItem {
  icon: JSX.Element;
  title: string;
  rightEle?: JSX.Element | null;
  onClick?: () => void;
}

const MenuItem = ({icon, title, rightEle, onClick}: IMenuItem) => {
  // const {t} = useTranslation();
  return (
    <div
      onClick={() => onClick?.()}
      className="p-3 flex flex-row justify-between items-center bg-[var(--button-s)]">
      <div className="flex flex-row items-center gap-4">
        <div>{icon}</div>
        <div className="text-t1 text-sm font-medium">{title}</div>
      </div>
      {!!rightEle && <div>{rightEle}</div>}
    </div>
  );
};

export default MenuItem;
