import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import HomeFilter, {
  HomeFilterValues,
} from '../../casino/component/filter/filter';
import React from 'react';
import {useRequest} from 'ahooks';
// import {OtherGameTypeListItem} from '../../casino/casino.type';
import Category from '@/components/v2/business/category/category';
import CasinoGamesCard from '@/components/v2/business/category/casino-games-card';
import {Button} from 'antd-mobile';
import styles from './slot-games-content.module.scss';
import {
  getDistinctField,
  getOtherGameTypeList,
} from '@/pages/casino/casino.service';
import {Grid} from 'antd-mobile';
import {OtherGameSubListItem} from '@/pages/casino/casino.type';
import {useTranslation} from 'react-i18next';
import {toFirstUpperCase} from '@/utils';
import {toAllCasinoGame} from '@/common-pages/game-navigate';
import NoData from '@/components/basic/error-pages/no-data';
import Spin from '@/components/v2/basic/spin';
interface SlotsGamesContentProps {
  tabKey: string;
}

const SlotsGamesContent: FC<SlotsGamesContentProps> = ({tabKey}) => {
  const [filters, setFilters] = useState<HomeFilterValues>({
    provider: '',
    sort: '',
  });
  const {t} = useTranslation();
  const {data: providerList, loading: providerLoading} = useRequest(
    () => getDistinctField(tabKey),
    {
      refreshDeps: [tabKey],
    },
  );
  const {data, loading} = useRequest(
    () => {
      return getOtherGameTypeList({
        gameType: tabKey,
        provider: filters.provider,
        sort: filters.sort,
      });
    },
    {
      refreshDeps: [tabKey, filters.provider, filters.sort],
      debounceWait: 100,
    },
  );
  const providerOptions = useMemo(() => {
    return (
      providerList?.map(item => ({
        label: toFirstUpperCase(item),
        value: item,
      })) || []
    );
  }, [providerList]);

  useEffect(() => {
    setFilters(_filters => ({
      ..._filters,
      provider: '',
      sort: '',
    }));
  }, [tabKey]);

  const filtedData = useMemo(() => {
    return (
      data?.filter(
        item =>
          (!filters.provider || item.gameType === filters.provider) &&
          item.gamesVoList &&
          item.gamesVoList?.length > 0,
      ) || []
    );
  }, [filters.provider, data]);

  const initialMaxItemLength = 6;
  const [providerShowAllListMap, setProviderShowAllListMap] = useState<
    Record<string, boolean>
  >({});

  useEffect(() => {
    const map: Record<string, boolean> = {};
    data?.forEach(item => {
      map[item.gameType] = false;
    });
    setProviderShowAllListMap(map);
  }, [data]);

  const onlyPic = useMemo(() => {
    return true;
  }, []);

  const renderItem = useCallback(
    (item: OtherGameSubListItem) => {
      return (
        <CasinoGamesCard
          onClick={() => {
            toAllCasinoGame(item.id);
          }}
          height="9.25rem"
          pic={item.gamePic}
          title={item.name}
          desc={item.provider}
          odds={item.odds || '0%'}
          onlyPic={onlyPic}
        />
      );
    },
    [onlyPic],
  );

  return (
    <Spin loading={providerLoading || loading}>
      {(providerOptions && providerOptions.length) ||
      (filtedData && filtedData.length) ? (
        <div className="flex flex-col gap-2">
          {providerOptions && providerOptions.length ? (
            <div className="sticky top-12 bg-bg z-20">
              <HomeFilter
                values={filters}
                onChange={setFilters}
                hideCategories
                customProvider={providerOptions}
              />
            </div>
          ) : null}
          {filtedData && filtedData.length ? (
            filtedData.length > 1 ? (
              <div className="flex flex-col gap-5">
                {filtedData.map(otherGameTypes => {
                  const needShowAllButton =
                    otherGameTypes.gamesVoList &&
                    !providerShowAllListMap[otherGameTypes.gameType] &&
                    otherGameTypes.gamesVoList.length > initialMaxItemLength;
                  const list = otherGameTypes.gamesVoList
                    ? needShowAllButton
                      ? otherGameTypes.gamesVoList.slice(
                          0,
                          initialMaxItemLength,
                        )
                      : otherGameTypes.gamesVoList
                    : [];
                  const bottomContent = needShowAllButton ? (
                    <div className="flex w-full items-center justify-center pt-3">
                      <Button
                        onClick={() =>
                          setProviderShowAllListMap(map => ({
                            ...map,
                            [otherGameTypes.gameType]: true,
                          }))
                        }
                        className="w-[10rem] h-10 flex justify-center items-center bg-button-s text-t1 rounded-lg text-xs font-bold">
                        {t('label.allGames')}
                      </Button>
                    </div>
                  ) : null;
                  return (
                    <Category
                      key={otherGameTypes.gameType}
                      icon={otherGameTypes.gameTypePic}
                      title={
                        otherGameTypes.gameTypePic
                          ? ''
                          : otherGameTypes.gameType
                      }
                      col={3}
                      list={list}
                      renderItem={renderItem}
                      footer={bottomContent}
                      className={
                        onlyPic ? styles['category-onlypic'] : undefined
                      }
                    />
                  );
                })}
              </div>
            ) : (
              <div
                className={`flex flex-col px-3 ${
                  onlyPic ? styles['category-onlypic'] : styles.category
                }`}>
                <Grid columns={2} className="grid-area">
                  {filtedData[0].gamesVoList?.map((item, _index) => (
                    <Grid.Item key={_index}>{renderItem(item)}</Grid.Item>
                  ))}
                </Grid>
              </div>
            )
          ) : (
            <div className="h-[18rem]">
              <NoData />
            </div>
          )}
        </div>
      ) : (
        <div className="h-[18rem]">
          <NoData />
        </div>
      )}
    </Spin>
  );
};

export default SlotsGamesContent;
