import NavTitle from '@/components/v2/basic/nav-title';
import Balance from '@/components/v2/business/balance/balance';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import HomeFilter, {HomeFilterValues} from './component/filter/filter';
import {getDistinctFieldValues} from './casino.service';
import {useRequest, useDebounceFn} from 'ahooks';
import HomeSide, {useTop} from '@/components/v2/business/homeside';
import {postNewGameList} from '@/common-pages/casino/casino.service';
import {useInfiniteScroll} from 'ahooks';
import {Grid, InfiniteScroll} from 'antd-mobile';
import NoData from '@/components/basic/error-pages/no-data';
import CasinoGamesCard from '@/components/v2/business/category/casino-games-card';
import Footer from '@/components/v2/business/footer/footer';
import {useTranslation} from 'react-i18next';
import Spin from '@/components/v2/basic/spin';
import {useRoute} from '@react-navigation/native';
import {SafeAny} from '@/types';
import {toFirstUpperCase} from '@/utils';
import {toAllCasinoGame} from '@/common-pages/game-navigate';

const Providers = () => {
  const {params} = useRoute<SafeAny>();

  const [filters, setFilters] = useState<HomeFilterValues>({});
  const {t} = useTranslation();
  const {data: providers} = useRequest(() =>
    getDistinctFieldValues('provider'),
  );
  const pageSize = 10;
  const loadList = useCallback(
    (pageNo: number) => {
      return postNewGameList({
        categoryId: 5,
        gameType: '',
        provider: filters.provider,
        isAsc:
          filters.sort != null
            ? filters.sort === '1'
              ? true
              : false
            : undefined,
        orderByField: filters.sort != null ? 'name' : undefined,
        pageNo: pageNo,
        pageSize,
      }).then(res => ({
        list: res.content,
        total: res.totalSize,
      }));
    },
    [filters.provider, filters.sort],
  );
  useEffect(() => {
    const findResult = providers?.find(
      item =>
        item &&
        params?.provider &&
        item.toLowerCase() === params.provider.toLowerCase(),
    );
    if (findResult) {
      setFilters(filter => ({
        ...filter,
        provider: findResult,
      }));
    }
  }, [params?.provider, providers]);
  const {
    data: gameList,
    loading,
    loadMoreAsync,
    reload,
  } = useInfiniteScroll(
    d => {
      const page = d ? Math.ceil(d.list.length / pageSize) + 1 : 1;
      return loadList(page);
    },
    {
      manual: true,
    },
  );
  const {parentRef, hasTop, onTop} = useTop<HTMLDivElement>();
  const {run: debounceReload} = useDebounceFn(reload, {wait: 100});

  useEffect(() => {
    onTop(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.category, filters.provider, filters.sort]);
  useEffect(() => {
    if (providers?.length) {
      debounceReload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.category, filters.provider, filters.sort, providers]);
  const hasMore = !!gameList && gameList.list.length < gameList.total;
  const providerOptions = useMemo(() => {
    return (
      providers?.map(item => ({
        label: toFirstUpperCase(item),
        value: item,
      })) || []
    );
  }, [providers]);

  return (
    <div className="flex flex-col overflow-hidden bg-bg w-full h-screen">
      <NavTitle
        title={filters.category || t('category.label.provider')}
        rightNode={<Balance />}
      />
      <Spin loading={loading}>
        <div
          className="flex flex-col flex-1 overflow-auto gap-2"
          ref={parentRef}>
          <div className="sticky top-0 z-20">
            <HomeFilter
              values={filters}
              onChange={setFilters}
              customProvider={providerOptions}
              hideCategories
            />
          </div>
          {!gameList?.list && <NoData />}
          <Grid columns={2} gap={'.5rem'} className="px-3">
            {gameList?.list.map(item => (
              <CasinoGamesCard
                key={item.id}
                title={item.name}
                desc={item.provider}
                pic={item.gamePic}
                odds={item.odds}
                onClick={() => toAllCasinoGame(item.id)}
              />
            ))}
          </Grid>

          <InfiniteScroll
            loadMore={async () => {
              await loadMoreAsync();
            }}
            hasMore={hasMore}
            className="flex flex-row items-center justify-center p-0">
            <Footer />
          </InfiniteScroll>
        </div>
      </Spin>

      <HomeSide hasTop={hasTop} onTop={onTop} />
    </div>
  );
};

export default Providers;
