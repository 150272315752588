import Picker, {PickerOptionsItem} from '@/components/v2/basic/picker/picker';
import {useRequest} from 'ahooks';
import {getCasinoTabs} from '../../casino.service';
import {FC, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import React from 'react';
import styles from './filter.module.scss';

export interface HomeFilterValues {
  category?: string;
  provider?: string;
  sort?: string;
}

interface HomeFilterProps {
  hideCategories?: boolean;
  hideProviders?: boolean;
  hideDefault?: boolean;
  values: HomeFilterValues;
  onChange: (value: HomeFilterValues) => void;
  customProvider?: PickerOptionsItem[];
}

const HomeFilter: FC<HomeFilterProps> = ({
  hideCategories,
  hideProviders,
  hideDefault,
  values,
  onChange,
  customProvider,
}) => {
  const {data: gameTypeList, run: getGameTypes} = useRequest(
    () => getCasinoTabs(),
    {
      manual: true,
    },
  );
  useEffect(() => {
    if (!hideCategories) {
      getGameTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideCategories, hideProviders, customProvider]);
  const {t} = useTranslation();
  const sortBy = useMemo(() => {
    return [
      {
        label: t('category.label.popular'),
        value: '',
      },
      {
        label: 'A-Z',
        value: '1',
      },
      {
        label: 'Z-A',
        value: '0',
      },
    ];
  }, [t]);
  return (
    <div className={`flex flex-row p-3 gap-1.5 ${styles.filter}`}>
      {!hideCategories && (
        <Picker
          className={styles.picker}
          name={t('category.label.categories')}
          options={gameTypeList?.map(item => ({
            label: item.moduleName,
            value: item.moduleType.toLowerCase().includes('all')
              ? ''
              : item.moduleType,
          }))}
          value={values.category || ''}
          onChange={value =>
            onChange({
              ...values,
              category: value,
            })
          }
        />
      )}
      {!hideProviders && (
        <Picker
          className={styles.picker}
          name={t('category.label.provider')}
          options={customProvider ? customProvider : []}
          value={values.provider || ''}
          onChange={value =>
            onChange({
              ...values,
              provider: value,
            })
          }
        />
      )}
      {!hideDefault && (
        <Picker
          className={styles.picker}
          name={t('category.label.default')}
          options={sortBy}
          value={values.sort || ''}
          onChange={value =>
            onChange({
              ...values,
              sort: value,
            })
          }
        />
      )}
    </div>
  );
};

export default HomeFilter;
