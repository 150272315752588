import {LinkingOptions} from '@react-navigation/native';
import {mainPageList} from './main-nav.config';
import globalStore from './services/global.state';
import routes from './route.config';

const linking: LinkingOptions<{}> = {
  prefixes: ['https://99lotterys.com', '99lotto://'],
  config: {
    screens: {
      Index: {
        screens: mainPageList.reduce((data, item) => {
          data[item.name] = item.link;
          return data;
        }, {} as {[k: string]: string}),
      },
      ...Object.values(routes).reduce((data, route) => {
        data[route.name] = route.link;
        return data;
      }, {} as {[k: string]: string}),
    },
  },
};

globalStore.setItem('linking', linking);

export {routes, linking};
