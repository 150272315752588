import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import HomeFilter, {HomeFilterValues} from '../component/filter/filter';
import {getDistinctField, getDistinctFieldValues} from '../casino.service';
import {useRequest, useDebounceFn} from 'ahooks';
import {postNewGameList} from '@/common-pages/casino/casino.service';
import {useInfiniteScroll} from 'ahooks';
import {Grid, InfiniteScroll} from 'antd-mobile';
import NoData from '@/components/basic/error-pages/no-data';
import CasinoGamesCard from '@/components/v2/business/category/casino-games-card';
import Footer from '@/components/v2/business/footer/footer';

import {toFirstUpperCase} from '@/utils';
import {toAllCasinoGame} from '@/common-pages/game-navigate';
import {NoMoreData} from '@/components/basic/default-page';

interface CategoryContentProps {
  hideCategories?: boolean;
  category?: string;
  filterClass?: string;
  onCategoryChange?: (category: string) => void;
  onLoading?: (loading: boolean) => void;
  onReloadList?: () => void;
  hasFooter?: boolean;
}

const CategoryContent: FC<CategoryContentProps> = ({
  hideCategories = false,
  category,
  filterClass = '',
  hasFooter = true,
  onCategoryChange,
  onLoading,
  onReloadList,
}) => {
  const [filters, setFilters] = useState<HomeFilterValues>({
    category: category || '',
  });
  const {data: providers} = useRequest(
    () =>
      filters.category
        ? getDistinctField(filters.category)
        : getDistinctFieldValues('provider'),
    {
      refreshDeps: [filters.category],
    },
  );
  const pageSize = 10;
  const isLive = useMemo(() => {
    return filters.category?.toLowerCase().includes('live');
  }, [filters.category]);
  const loadList = useCallback(
    (pageNo: number) => {
      return postNewGameList({
        categoryId: isLive ? 6 : 5,
        gameType: isLive ? '' : filters.category,
        provider: filters.provider,
        isAsc:
          filters.sort != null
            ? filters.sort === '1'
              ? true
              : false
            : undefined,
        orderByField: filters.sort != null ? 'name' : undefined,
        pageNo: pageNo,
        pageSize,
      }).then(res => ({
        list: res.content,
        total: res.totalSize,
      }));
    },
    [filters.category, filters.provider, filters.sort, isLive],
  );

  const {
    data: gameList,
    loading,
    loadMoreAsync,
    reload,
  } = useInfiniteScroll(
    d => {
      const page = d ? Math.ceil(d.list.length / pageSize) + 1 : 1;
      return loadList(page);
    },
    {
      manual: true,
    },
  );
  const {run: debounceReload} = useDebounceFn(reload, {wait: 100});
  useEffect(() => {
    onReloadList?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.category, filters.provider, filters.sort]);
  useEffect(() => {
    debounceReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.category, filters.provider, filters.sort]);
  useEffect(() => {
    if (hideCategories) {
      setFilters(filter => ({
        ...filter,
        category,
      }));
    }
  }, [category, hideCategories]);
  useEffect(() => {
    setFilters(filter => ({
      ...filter,
      provider: '',
    }));
    onCategoryChange?.(filters.category || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.category]);
  const hasMore = !!gameList && gameList.list.length < gameList.total;
  const providerOptions = useMemo(() => {
    return (
      providers?.map(item => ({
        label: toFirstUpperCase(item),
        value: item,
      })) || []
    );
  }, [providers]);

  useEffect(() => {
    onLoading?.(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <div className="flex flex-col gap-2">
      {(providerOptions.length > 1 || !hideCategories) && (
        <div className={`sticky z-20 ${filterClass || 'top-0'}`}>
          <HomeFilter
            values={filters}
            onChange={setFilters}
            customProvider={providerOptions}
            hideCategories={hideCategories}
          />
        </div>
      )}
      {!gameList?.list?.length && (
        <div className="h-[18rem]">
          <NoData />
        </div>
      )}
      <Grid columns={3} gap={'.5rem'} className="px-3">
        {gameList?.list.map(item => (
          <CasinoGamesCard
            key={item.id}
            title={item.name}
            desc={item.provider}
            pic={item.gamePic}
            odds={item.odds}
            height="9.25rem"
            onlyPic
            onClick={() => toAllCasinoGame(item.id)}
          />
        ))}
      </Grid>

      <InfiniteScroll
        loadMore={async () => {
          await loadMoreAsync();
        }}
        hasMore={hasMore}
        className="flex flex-row items-center justify-center p-0">
        {hasFooter ? <Footer /> : <NoMoreData />}
      </InfiniteScroll>
    </div>
  );
};

export default CategoryContent;
