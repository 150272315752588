const CurrencyMap = {
  default: '',
  USD: '$',
  INR: '₹',
  TRY: '₺',
};

CurrencyMap.default = CurrencyMap.INR;

export {CurrencyMap};
